export const ltDropdown = ['24 24', `
  <title>dropdown</title>
  <g id="Group_3648" data-name="Group 3648" transform="translate(-1299 -199)">
    <g id="Rectangle_19784" data-name="Rectangle 19784" transform="translate(1299 199)" fill="#fff" stroke="#000" stroke-width="1.3">
      <rect width="24" height="24" rx="3" stroke="none"/>
      <rect x="0.65" y="0.65" width="22.7" height="22.7" rx="2.35" fill="none"/>
    </g>
    <path id="Icon_material-keyboard-arrow-down" data-name="Icon material-keyboard-arrow-down" d="M8.2,10.489l4.337,3.987,4.319-3.987.938.939L12.54,16.36,7.265,11.428Z" transform="translate(1298.457 198.381)" stroke="#000" stroke-width="0.2"/>
  </g>
`]

export const ltFileupload = ['24 24', `
  <title>file upload</title>
  <g id="Icon_feather-upload" data-name="Icon feather-upload" transform="translate(-3.85 -5.686)">
    <path id="Path_3214" data-name="Path 3214" d="M27.765,22.5v5.17a2.585,2.585,0,0,1-2.585,2.585H7.085A2.585,2.585,0,0,1,4.5,27.67V22.5" transform="translate(0 -4.709)" fill="none" stroke="#000" stroke-linecap="square" stroke-linejoin="round" stroke-width="1.3"/>
    <path id="Path_3215" data-name="Path 3215" d="M19.8,9.151,15.151,4.5,10.5,9.151" transform="translate(0.982 1.836)" fill="none" stroke="#000" stroke-linecap="square" stroke-linejoin="round" stroke-width="1.3"/>
    <path id="Path_3216" data-name="Path 3216" d="M18,4.5V14.528" transform="translate(-1.867 2.665)" fill="none" stroke="#000" stroke-linecap="square" stroke-linejoin="round" stroke-width="1.3"/>
  </g>
`]

export const ltGroup = ['26 26', `
  <g id="Group_3646" data-name="Group 3646" transform="translate(-1298 -198)">
    <g id="Rectangle_19784" data-name="Rectangle 19784" transform="translate(1299 199)" fill="#fff" stroke="#000" stroke-width="1.3">
      <rect width="24" height="24" rx="3" stroke="none"/>
      <rect x="0.65" y="0.65" width="22.7" height="22.7" rx="2.35" fill="none"/>
    </g>
    <g id="Ellipse_424" data-name="Ellipse 424" transform="translate(1298 198)" fill="#fff" stroke="#000" stroke-width="1.3">
      <circle cx="2.5" cy="2.5" r="2.5" stroke="none"/>
      <circle cx="2.5" cy="2.5" r="1.85" fill="none"/>
    </g>
    <g id="Ellipse_427" data-name="Ellipse 427" transform="translate(1298 219)" fill="#fff" stroke="#000" stroke-width="1.3">
      <circle cx="2.5" cy="2.5" r="2.5" stroke="none"/>
      <circle cx="2.5" cy="2.5" r="1.85" fill="none"/>
    </g>
    <g id="Ellipse_425" data-name="Ellipse 425" transform="translate(1319 198)" fill="#fff" stroke="#000" stroke-width="1.3">
      <circle cx="2.5" cy="2.5" r="2.5" stroke="none"/>
      <circle cx="2.5" cy="2.5" r="1.85" fill="none"/>
    </g>
    <g id="Ellipse_426" data-name="Ellipse 426" transform="translate(1319 219)" fill="#fff" stroke="#000" stroke-width="1.3">
      <circle cx="2.5" cy="2.5" r="2.5" stroke="none"/>
      <circle cx="2.5" cy="2.5" r="1.85" fill="none"/>
    </g>
  </g>
`]

export const ltText = ['24 24', `
  <g id="Group_3647" data-name="Group 3647" transform="translate(-1299 -199)">
    <g id="Rectangle_19784" data-name="Rectangle 19784" transform="translate(1299 199)" fill="#fff" stroke="#000" stroke-width="1.3">
      <rect width="24" height="24" rx="3" stroke="none"/>
      <rect x="0.65" y="0.65" width="22.7" height="22.7" rx="2.35" fill="none"/>
    </g>
    <path id="Icon_material-title" data-name="Icon material-title" d="M7.5,6V8.143h3.929v8.571h2.143V8.143H17.5V6Z" transform="translate(1298.5 200)"/>
  </g>
`]

export const ltUserinput = ['24 24', `
  <g>
  <title>Layer 1</title>
  <g data-name="Group 3663" id="Group_3663">
  <g data-name="Group 3662" id="Group_3662">
    <g stroke-width="1.3" stroke="#000" fill="#fff" data-name="Rectangle 19784" id="Rectangle_19784">
    <rect id="svg_1" x="0" y="0" rx="3" height="24" width="24"/>
    <rect id="svg_2" fill="none" rx="2.35" height="22.7" width="22.7" y="0.65" x="0.65"/>
    </g>
  </g>
  <path d="m15.19895,6.89799l0,-1a0.3,0.3 0 0 0 -0.3,-0.3a3.718,3.718 0 0 0 -2.9,0.949a3.712,3.712 0 0 0 -2.9,-0.949a0.3,0.3 0 0 0 -0.3,0.3l0,0.991a0.3,0.3 0 0 0 0.3,0.3c0.649,-0.008 2.1,0.087 2.1,1.211c0.03,0.017 0.009,7.213 0,7.2c0,1.124 -1.4,1.2 -2.1,1.2a0.3,0.3 0 0 0 -0.3,0.3l0,1a0.3,0.3 0 0 0 0.3,0.3a3.718,3.718 0 0 0 2.9,-0.948a3.712,3.712 0 0 0 2.9,0.946a0.3,0.3 0 0 0 0.3,-0.3l0,-0.991a0.3,0.3 0 0 0 -0.3,-0.3c-0.651,0.008 -2.1,-0.082 -2.1,-1.209c-0.038,0.022 0.007,-7.194 0,-7.2c0,-1.118 1.4,-1.2 2.1,-1.2a0.3,0.3 0 0 0 0.3,-0.3z" data-name="Icon awesome-i-cursor" id="Icon_awesome-i-cursor"/>
  </g>
  </g>
`]

export const ltTemperature = ['11.612 26.077', `
  <g id="Group_3243" data-name="Group 3243" transform="translate(-762.672 -323.098)">
    <g id="Solid" transform="translate(762.893 323.248)">
      <path id="Path_3189" data-name="Path 3189" d="M24.585,27.777a5.582,5.582,0,0,1-3.007-10.286V5.007a3.007,3.007,0,0,1,6.015,0V17.491a5.582,5.582,0,0,1-3.007,10.286Zm0-24.918a2.151,2.151,0,0,0-2.148,2.148V17.732a.43.43,0,0,1-.215.372,4.726,4.726,0,1,0,4.726,0,.43.43,0,0,1-.215-.372V5.007a2.151,2.151,0,0,0-2.148-2.148Z" transform="translate(-18.999 -2)" fill="var(--ci-primary-color, currentColor)" stroke="var(--ci-primary-color, currentColor)" stroke-width="0.3"/>
    </g>
    <path id="Path_3204" data-name="Path 3204" d="M0,0H3.139" transform="translate(765.901 330.816)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-width="1.2"/>
    <path id="Path_3205" data-name="Path 3205" d="M0,0H3.139" transform="translate(765.901 333.606)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-width="1.2"/>
    <path id="Path_3206" data-name="Path 3206" d="M0,0H3.139" transform="translate(765.901 336.395)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-width="1.2"/>
  </g>
`]

export const ltHumidity = ['18.767 22.713', `
  <g id="humidade" transform="translate(-284.4 -179.643)">
    <g id="Humidity" transform="translate(285 184.878)">
      <g id="Group_3231" data-name="Group 3231" transform="translate(0 0)">
        <path id="Path_3188" data-name="Path 3188" d="M70.091,44.033a5.833,5.833,0,0,1-4.164,1.71,5.949,5.949,0,0,1-4.164-1.71,5.833,5.833,0,0,1-1.71-4.164c0-2.317,3.116-6.922,5.185-9.983.248-.359.469-.717.689-1.02.221.3.441.662.689,1.02,2.068,3.089,5.185,7.667,5.185,9.983A5.949,5.949,0,0,1,70.091,44.033Z" transform="translate(-60.052 -28.865)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
      </g>
    </g>
    <g id="Humidity-2" data-name="Humidity" transform="translate(295.775 180.243)">
      <g id="Group_3231-2" data-name="Group 3231" transform="translate(0 0)">
        <path id="Path_3188-2" data-name="Path 3188" d="M65.855,37.633a3.372,3.372,0,0,1-2.407.988,3.439,3.439,0,0,1-2.407-.988,3.372,3.372,0,0,1-.988-2.407c0-1.339,1.8-4,3-5.771.143-.207.271-.414.4-.59.128.175.255.383.4.59,1.2,1.786,3,4.432,3,5.771A3.439,3.439,0,0,1,65.855,37.633Z" transform="translate(-60.052 -28.865)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
      </g>
    </g>
  </g>
`]

export const ltBurner = ['25.938 24.325', `
  <g id="Queimador" transform="translate(0.6 0.6)">
    <path id="Path_3196" data-name="Path 3196" d="M2.9,353.727H1.933A1.933,1.933,0,0,1,0,351.794v-.241a1.933,1.933,0,0,1,1.933-1.933h3.1" transform="translate(0 -333.539)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
    <path id="Path_3197" data-name="Path 3197" d="M65.941,393.51,59,396.174a1.933,1.933,0,0,1-2.5-1.111l-.086-.225a1.933,1.933,0,0,1,1.111-2.5l2.761-1.061" transform="translate(-53.572 -373.186)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
    <path id="Path_3198" data-name="Path 3198" d="M406.755,349.621h3.152a1.933,1.933,0,0,1,1.933,1.933v.241a1.933,1.933,0,0,1-1.933,1.933h-.966" transform="translate(-387.102 -333.539)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
    <path id="Path_3199" data-name="Path 3199" d="M256,393.739l6.937,2.664a1.933,1.933,0,0,0,2.5-1.111l.086-.225a1.933,1.933,0,0,0-1.111-2.5l-2.739-1.049" transform="translate(-243.631 -373.415)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
    <path id="Path_3200" data-name="Path 3200" d="M90.657,37.056a8.438,8.438,0,0,0,7.294-10.695L96.811,22.3h0l-2.12,2.213-4.16-6.631-.7-1.112h0l-4.857,7.743L82.856,22.3l-1.14,4.064A8.432,8.432,0,0,0,89.625,37.09l.209,0h0l.209,0,.143,0,.113,0C90.419,37.076,90.538,37.067,90.657,37.056Z" transform="translate(-77.465 -16.768)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
    <path id="Path_3201" data-name="Path 3201" d="M174.344,225.646q-.007-.19-.032-.382a4.449,4.449,0,0,0-.13-.642l-.59-2.1-1.1,1.146-2.515-4.008-2.515,4.008-1.1-1.146-.59,2.1a4.365,4.365,0,0,0,4.094,5.554h.216a4.344,4.344,0,0,0,.9-.11l.123-.03a4.248,4.248,0,0,0,.591-.2l.113-.05q.112-.052.22-.11.162-.087.316-.187a4.33,4.33,0,0,0,.484-.366q.091-.08.178-.164t.169-.174l.081-.09q.079-.091.154-.187a4.434,4.434,0,0,0,.507-.823q.026-.055.05-.11a4.367,4.367,0,0,0,.366-1.547Q174.351,225.837,174.344,225.646Z" transform="translate(-157.611 -209.853)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
  </g>
`]

export const ltMode = ['20.423 16.86', `
  <g id="Group_3556" data-name="Group 3556" transform="translate(36.889 45.034)">
    <rect id="Rectangle_19864" data-name="Rectangle 19864" width="19.123" height="15.56" transform="translate(-36.239 -44.384)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
    <g id="Group_3557" data-name="Group 3557">
      <path id="Path_3233" data-name="Path 3233" d="M0,0H7.662" transform="translate(-28.183 -39.345)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-width="1.3"/>
      <path id="Path_3234" data-name="Path 3234" d="M0,0H1.274" transform="translate(-33.001 -39.345)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-width="1.3"/>
      <circle id="Ellipse_425" data-name="Ellipse 425" cx="1.507" cy="1.507" r="1.507" transform="translate(-31.727 -40.888)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-width="1.3"/>
    </g>
    <g id="Group_3558" data-name="Group 3558" transform="translate(-53.521 -72.763) rotate(180)">
      <path id="Path_3233-2" data-name="Path 3233" d="M0,0H7.662" transform="translate(-28.183 -39.345)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-width="1.3"/>
      <path id="Path_3234-2" data-name="Path 3234" d="M0,0H1.274" transform="translate(-33.001 -39.345)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-width="1.3"/>
      <circle id="Ellipse_425-2" data-name="Ellipse 425" cx="1.507" cy="1.507" r="1.507" transform="translate(-31.727 -40.888)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-width="1.3"/>
    </g>
  </g>
`]

export const ltWindowside = ['19.857 18.365', `
  <g id="janela_lateral" data-name="janela lateral" transform="translate(100.268 20.672)">
    <path id="Path_3229" data-name="Path 3229" d="M-81.061-20.022V-2.957l-18.557-2V-18.022Z" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
    <line id="Line_425" data-name="Line 425" y1="14.531" transform="translate(-91.905 -18.817)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
    <line id="Line_426" data-name="Line 426" x2="18.063" transform="translate(-99.275 -11.577)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
  </g>
`]

export const ltWindowtop = ['18.365 19.857', `
  <g id="janela_topo" data-name="janela topo" transform="translate(127.345 21.418)">
    <path id="Path_3230" data-name="Path 3230" d="M-109.63-2.211H-126.7l2-18.557h13.065Z" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
    <line id="Line_427" data-name="Line 427" x2="14.531" transform="translate(-125.366 -13.055)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
    <line id="Line_428" data-name="Line 428" y2="18.063" transform="translate(-118.075 -20.424)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
  </g>
`]

export const ltShadow = ['22.808 20.201', `
  <g id="Tela" transform="translate(-285.097 -379.35)">
    <path id="Path_3231" data-name="Path 3231" d="M-117.239-49.081l10.679-7.251,10.321,7.251" transform="translate(403.239 436.332)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
    <g id="Group_3555" data-name="Group 3555" transform="translate(293.105 387.798)">
      <line id="Line_430" data-name="Line 430" y2="5.385" transform="translate(3.395 5.311)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.3"/>
      <path id="Path_3232" data-name="Path 3232" d="M-24.417.983a3.278,3.278,0,0,1-2.1,1.878A3.4,3.4,0,0,1-30.943-.381a6.994,6.994,0,0,1,1.81-3.953,2.093,2.093,0,0,1,3.242-.021C-24.768-2.992-23.623-.977-24.417.983Z" transform="translate(30.943 5.114)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
    </g>
    <line id="Line_431" data-name="Line 431" x2="16.201" transform="translate(288.4 398.901)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-width="1.3" stroke-dasharray="3"/>
  </g>
`]

export const ltGraph = [ "20.588 22.504", `
  <path id="Path_2532" data-name="Path 2532" d="M62.537,187.855H61.108V168.406a1.754,1.754,0,0,0-1.752-1.752H56.161a1.754,1.754,0,0,0-1.752,1.752v19.449H51.432V177.2a1.754,1.754,0,0,0-1.752-1.752H46.485a1.754,1.754,0,0,0-1.752,1.752v10.653c.075.033,2.963-.09-1.481,0a.652.652,0,1,0,0,1.3H62.537a.652.652,0,1,0,0-1.3Zm-12.408,0H46.037V177.2a.449.449,0,0,1,.448-.448H49.68a.449.449,0,0,1,.448.448Zm9.676,0H55.713V168.406a.449.449,0,0,1,.448-.448h3.195a.449.449,0,0,1,.448.448v19.449Z" transform="translate(-42.601 -166.654)"/>
`]


export const ltWindSpeed = [ "21.041 20.913", `
  <path id="WindSpeed" d="M14.165,7.793a2.644,2.644,0,0,1,3.219-.434,2.7,2.7,0,0,1,1.241,3.03,2.668,2.668,0,0,1-2.583,1.988H10M22.122,25.72a2.644,2.644,0,0,0,3.219.434,2.7,2.7,0,0,0,1.241-3.03A2.668,2.668,0,0,0,24,21.135H10m14.015-10.12a3.305,3.305,0,0,1,4.016-.531,3.375,3.375,0,0,1,1.551,3.778,3.335,3.335,0,0,1-3.21,2.494H10" transform="translate(-9.35 -6.266)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3" stroke="var(--ci-primary-color, currentColor)" />
`]

export const ltWindDir = [ "20.627 16.223", `
  <path id="Path_3211" data-name="Path 3211" d="M455.453,134.418l-19.3,2.021-.032-11.934,19.327,2.025Z" transform="translate(-436.126 -122.37)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.3"/>
  <line id="Line_259" data-name="Line 259" y2="16.223" transform="translate(0)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-width="1.3"/>
  <line id="Line_262" data-name="Line 262" y2="10.042" transform="translate(13.28 3.088)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-width="1.3"/>
  <line id="Line_260" data-name="Line 260" y2="10.573" transform="translate(7.275 3.088)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-width="1.3"/>
`]

export const ltRainRate = [ "20.072 20.095", `
    <path id="Path_3207" data-name="Path 3207" d="M24,19.5v6.833" transform="translate(-11.306 -9.251)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
    <path id="Path_3208" data-name="Path 3208" d="M12,19.5v6.833" transform="translate(-6.073 -9.251)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
    <path id="Path_3209" data-name="Path 3209" d="M18,22.5v6.833" transform="translate(-8.69 -10.543)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
    <path id="Path_3210" data-name="Path 3210" d="M17.568,14.806a4.277,4.277,0,0,0-1.692-8.182H14.81A6.748,6.748,0,0,0,1.937,5.893a6.874,6.874,0,0,0,2.1,7.778" transform="translate(-1.49 -1.5)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
`]

export const ltRaining = [ "21.77 20.089", `
  <path id="IsRaining" d="M21.97,12.395a10.273,10.273,0,0,0-20.47,0Zm-4.652,6.576a2.792,2.792,0,1,1-5.583,0V12.395" transform="translate(-0.85 -2.35)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
`]

export const ltMobile = [ "12 20", `
  <g id="Group_3701" data-name="Group 3701" transform="translate(-1250 -21)">
    <g id="Rectangle_19879" data-name="Rectangle 19879" transform="translate(1250 21)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-width="1.3">
      <rect width="12" height="20" rx="2" stroke="none"/>
      <rect x="0.65" y="0.65" width="10.7" height="18.7" rx="1.35" fill="none"/>
    </g>
    <line id="Line_464" data-name="Line 464" x2="1.356" transform="translate(1255.5 37.271)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="square" stroke-width="1.3"/>
  </g>
`]

export const ltTablet = [ "16 20", `
  <g id="Group_3704" data-name="Group 3704" transform="translate(0.125)">
    <g id="Rectangle_19879" data-name="Rectangle 19879" transform="translate(-0.125 0)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-width="1.3">
      <rect width="16" height="20" rx="2" stroke="none"/>
      <rect x="0.65" y="0.65" width="14.7" height="18.7" rx="1.35" fill="none"/>
    </g>
    <line id="Line_464" data-name="Line 464" x2="2.712" transform="translate(6.541 16.271)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="square" stroke-width="1.3"/>
  </g>
`]

export const ltLaptop = [ "22 20", `
  <g id="Group_3702" data-name="Group 3702" transform="translate(-0.108)">
    <g id="Rectangle_19879" data-name="Rectangle 19879" transform="translate(0.108 0)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-width="1.3">
      <rect width="22" height="20" rx="2" stroke="none"/>
      <rect x="0.65" y="0.65" width="20.7" height="18.7" rx="1.35" fill="none"/>
    </g>
    <line id="Line_464" data-name="Line 464" x2="2.712" transform="translate(9.785 16.271)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="square" stroke-width="1.3"/>
  </g>
`]

export const ltDesktop = [ "24 20.65", `
  <g id="Group_3703" data-name="Group 3703" transform="translate(0.083)">
    <g id="Rectangle_19879" data-name="Rectangle 19879" transform="translate(-0.083 0)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-width="1.3">
      <rect width="24" height="18" rx="2" stroke="none"/>
      <rect x="0.65" y="0.65" width="22.7" height="16.7" rx="1.35" fill="none"/>
    </g>
    <line id="Line_464" data-name="Line 464" x2="8.136" transform="translate(7.919 20)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="square" stroke-width="1.3"/>
    <line id="Line_478" data-name="Line 478" y2="1.5" transform="translate(11.988 18)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="square" stroke-width="1.3"/>
  </g>
`]

export const ltLargeDesktop = [ "28 20.65", `
  <g id="Group_3708" data-name="Group 3708" transform="translate(4719.241 1241)">
    <g id="Group_3706" data-name="Group 3706" transform="translate(-4719 -1241)">
      <g id="Rectangle_19879" data-name="Rectangle 19879" transform="translate(-0.241 0)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-width="1.3">
        <rect width="28" height="18" rx="2" stroke="none"/>
        <rect x="0.65" y="0.65" width="26.7" height="16.7" rx="1.35" fill="none"/>
      </g>
      <line id="Line_464" data-name="Line 464" x2="8.136" transform="translate(9.784 20)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="square" stroke-width="1.3"/>
    </g>
    <line id="Line_479" data-name="Line 479" y2="1.5" transform="translate(-4705.24 -1223)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="square" stroke-width="1.3"/>
  </g>
`]

export const ltExtraLargeDesktop = [ "30 20.15", `
  <g id="Group_3709" data-name="Group 3709" transform="translate(4684 1241)">
    <g id="Group_3707" data-name="Group 3707" transform="translate(-4684.715 -1241)">
      <g id="Rectangle_19879" data-name="Rectangle 19879" transform="translate(0.715 0)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-width="1.3">
        <rect width="30" height="18" rx="2" stroke="none"/>
        <rect x="0.65" y="0.65" width="28.7" height="16.7" rx="1.35" fill="none"/>
      </g>
      <line id="Line_464" data-name="Line 464" x2="13" transform="translate(9.215 19.5)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="square" stroke-width="1.3"/>
    </g>
  </g>
`]

export const ltSave = [ "18.47 18.47", `
  <g id="Icon_feather-save" data-name="Icon feather-save" transform="translate(0.65 0.65)">
    <path id="Path_2967" data-name="Path 2967" d="M19.762,21.67H6.408A1.908,1.908,0,0,1,4.5,19.762V6.408A1.908,1.908,0,0,1,6.408,4.5H16.9L21.67,9.269V19.762A1.908,1.908,0,0,1,19.762,21.67Z" transform="translate(-4.5 -4.5)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
    <path id="Path_2968" data-name="Path 2968" d="M20.039,27.131V19.5H10.5v7.631" transform="translate(-6.684 -9.961)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
    <path id="Path_2969" data-name="Path 2969" d="M10.5,4.5V9.269h7.631V4.5" transform="translate(-6.684 -4.5)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
  </g>
`]

export const ltLayers = [ "24.916 18.679", `
  <g id="Icon_feather-layers" data-name="Icon feather-layers" transform="translate(-2.128 -2.35)">
    <path id="Path_3136" data-name="Path 3136" d="M14.586,3,3,8.793l11.586,5.793L26.171,8.793Z" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="square" stroke-linejoin="round" stroke-width="1.3"/>
    <path id="Path_3138" data-name="Path 3138" d="M3,18l11.586,5.793L26.171,18" transform="translate(0 -3.414)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="square" stroke-linejoin="round" stroke-width="1.3"/>
  </g>
`]

export const ltPencil = [ "17.17 17.17", `
  <path id="cil-pencil" d="M22.583,32.918l0,0a.9.9,0,0,0,.638.266.913.913,0,0,0,.294-.049l5.148-1.746,9.878-9.878a3.22,3.22,0,1,0-4.554-4.554l-9.878,9.878-1.746,5.147a.9.9,0,0,0,.217.932ZM34.81,17.781a2.06,2.06,0,1,1,2.913,2.913l-1.3,1.3L33.511,19.08Zm-9.686,9.686L32.691,19.9,35.6,22.814l-7.567,7.567-4.409,1.5Z" transform="translate(-22.317 -16.018)"/>
`]

export const ltPlus = [ "17 17.001", `
  <rect id="Rectangle_2" data-name="Rectangle 2" width="1.3" height="17.001" transform="translate(8)"/>
  <rect id="Rectangle_3" data-name="Rectangle 3" width="1.3" height="17.001" transform="translate(17 8) rotate(90)"/>
`]

export const ltSeparator = [ "1 28", `
  <rect id="Divider" width="1" height="28" fill="var(--ci-primary-color, currentColor)"/>
`]

export const ltOptions = [ "16 16", `
  <rect id="Rectangle_449" data-name="Rectangle 449" width="16" height="16" fill="none"/>
  <path id="Union_1" data-name="Union 1" d="M0,13.5A1.5,1.5,0,1,1,1.5,15,1.5,1.5,0,0,1,0,13.5Zm0-6A1.5,1.5,0,1,1,1.5,9,1.5,1.5,0,0,1,0,7.5Zm0-6A1.5,1.5,0,1,1,1.5,3,1.5,1.5,0,0,1,0,1.5Z" transform="translate(7 1)"/>
`]

export const ltClose = [ "13.152 12.941", `
  <g id="Group_3710" data-name="Group 3710" transform="translate(6.576 -5.657) rotate(45)">
    <rect id="Rectangle_2" data-name="Rectangle 2" width="1.3" height="17.001" transform="translate(8)"/>
    <rect id="Rectangle_3" data-name="Rectangle 3" width="1.3" height="17.001" transform="translate(17 8) rotate(90)"/>
  </g>
`]

// export const cisChevronLeft = [
//   `
//   <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>`
// ]

export const ltHome = [ "18.017 17.164", `
<g id="house" transform="translate(0.187 0.181)">
  <path id="Path_666" data-name="Path 666" d="M17.585,18.782,9.233,11.313a.234.234,0,0,0-.047-.037.494.494,0,0,0-.668-.011L.165,18.735a.494.494,0,0,0,.657.737l1.5-1.347v8.71a1.242,1.242,0,0,0,1.241,1.241H6.2a1.165,1.165,0,0,0,1.165-1.165V22.484a.179.179,0,0,1,.179-.179h2.661a.179.179,0,0,1,.179.179v4.428a1.165,1.165,0,0,0,1.165,1.165h2.636a1.411,1.411,0,0,0,1.241-1.555V18.172l1.5,1.347a.5.5,0,0,0,.7-.04A.5.5,0,0,0,17.585,18.782Zm-3.147,2.435v5.3c0,.354-.175.569-.256.569H11.547a.179.179,0,0,1-.179-.179V22.484A1.165,1.165,0,0,0,10.2,21.319H7.543a1.165,1.165,0,0,0-1.165,1.165v4.428a.179.179,0,0,1-.179.179H3.564a.256.256,0,0,1-.256-.256V17.242l5.541-4.95,5.589,5v3.928Z" transform="translate(0 -11.141)" stroke="#000" stroke-width="0.3"/>
</g>
`]

export const ltCalendar = [ "18.896 17.322", `
  <path id="Path_474" data-name="Path 474" d="M33.952,42.2H30.487V40h-1.26v2.2H21.669V40h-1.26v2.2H16.945a.946.946,0,0,0-.945.945V56.377a.946.946,0,0,0,.945.945H33.952a.946.946,0,0,0,.945-.945V43.149a.946.946,0,0,0-.945-.945Zm-.315,13.857H17.26v-12.6h3.149v1.575h1.26V43.464h7.559v1.575h1.26V43.464h3.149Z" transform="translate(-16 -40)"/>
  <rect id="Rectangle_16924" data-name="Rectangle 16924" width="1.18" height="1.18" transform="translate(3.946 7.373)"/>
  <rect id="Rectangle_16925" data-name="Rectangle 16925" width="1.18" height="1.18" transform="translate(7.389 7.373)"/>
  <rect id="Rectangle_16926" data-name="Rectangle 16926" width="1.18" height="1.18" transform="translate(10.52 7.373)"/>
  <rect id="Rectangle_16927" data-name="Rectangle 16927" width="1.18" height="1.18" transform="translate(13.964 7.373)"/>
  <rect id="Rectangle_16928" data-name="Rectangle 16928" width="1.18" height="1.18" transform="translate(3.946 10.192)"/>
  <rect id="Rectangle_16929" data-name="Rectangle 16929" width="1.18" height="1.18" transform="translate(7.389 10.192)"/>
  <rect id="Rectangle_16930" data-name="Rectangle 16930" width="1.18" height="1.18" transform="translate(10.52 10.192)"/>
  <rect id="Rectangle_16931" data-name="Rectangle 16931" width="1.18" height="1.18" transform="translate(13.964 10.192)"/>
  <rect id="Rectangle_16932" data-name="Rectangle 16932" width="1.18" height="1.18" transform="translate(3.946 13.01)"/>
  <rect id="Rectangle_16933" data-name="Rectangle 16933" width="1.18" height="1.18" transform="translate(7.389 13.01)"/>
  <rect id="Rectangle_16934" data-name="Rectangle 16934" width="1.18" height="1.18" transform="translate(10.52 13.01)"/>
  <rect id="Rectangle_16935" data-name="Rectangle 16935" width="1.18" height="1.18" transform="translate(13.964 13.01)"/>
  `]

  export const ltRecipe = [ "16.052 19.151", `
  <g id="Group_3822" data-name="Group 3822" transform="translate(3373.65 4711.875)">
    <g id="Group_3688" data-name="Group 3688" transform="translate(-3373 -4711.225)">
      <path id="Rectangle_19892" data-name="Rectangle 19892" d="M2.258,0H14.752a0,0,0,0,1,0,0V4.515a0,0,0,0,1,0,0H2.258A2.258,2.258,0,0,1,0,2.258v0A2.258,2.258,0,0,1,2.258,0Z" transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
      <path id="Path_3216" data-name="Path 3216" d="M-1094.727-146.533v15.816h-12.719a2.033,2.033,0,0,1-2.033-2.033v-13.783" transform="translate(1109.479 148.568)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
    </g>
    <line id="Line_472" data-name="Line 472" x2="7.898" transform="translate(-3369.242 -4697.515)" fill="none" stroke="#000" stroke-linecap="square" stroke-width="1.3"/>
    <line id="Line_473" data-name="Line 473" x2="7.898" transform="translate(-3369.242 -4700.674)" fill="none" stroke="#000" stroke-linecap="square" stroke-width="1.3"/>
  </g>
`]

export const ltMaintenace = [ "22.493 20.619", `
  <path id="Path_3320" data-name="Path 3320" d="M411.969,298.316H400V283h19.41v7.568" transform="translate(-399.35 -279.891)" fill="none" stroke="#000" stroke-linejoin="round" stroke-width="1.3"/>
  <line id="Line_490" data-name="Line 490" y1="5.542" transform="translate(5.755)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="1.3"/>
  <line id="Line_491" data-name="Line 491" y1="5.542" transform="translate(15.308)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="1.3"/>
  <g id="Group_3825" data-name="Group 3825" transform="translate(10.897 9.023)">
    <path id="Path_3321" data-name="Path 3321" d="M478.291,326.4h-1.057a.406.406,0,0,1-.287-.693l.748-.748a.583.583,0,0,0,0-.822l-.953-.953a.6.6,0,0,0-.822,0l-.748.748a.406.406,0,0,1-.693-.287v-1.057A.581.581,0,0,0,473.9,322H472.55a.581.581,0,0,0-.581.581v1.057a.406.406,0,0,1-.693.287l-.748-.748a.6.6,0,0,0-.822,0l-.953.953a.583.583,0,0,0,0,.822l.748.748a.406.406,0,0,1-.287.693h-1.057a.581.581,0,0,0-.581.581v1.347a.581.581,0,0,0,.581.581h1.057a.406.406,0,0,1,.287.693l-.748.748a.583.583,0,0,0,0,.822l.953.953a.6.6,0,0,0,.822,0l.748-.748a.406.406,0,0,1,.693.287v1.057a.582.582,0,0,0,.581.581H473.9a.581.581,0,0,0,.581-.581V331.66a.406.406,0,0,1,.693-.287l.748.748a.6.6,0,0,0,.822,0l.953-.953a.583.583,0,0,0,0-.822l-.748-.748a.406.406,0,0,1,.287-.693h1.057a.581.581,0,0,0,.581-.581v-1.347a.581.581,0,0,0-.581-.581Zm.163,1.929a.164.164,0,0,1-.163.163h-1.057a.824.824,0,0,0-.582,1.407l.747.748a.162.162,0,0,1,0,.231l-.952.952a.163.163,0,0,1-.231,0l-.748-.747a.824.824,0,0,0-1.407.582v1.057a.164.164,0,0,1-.163.163H472.55a.164.164,0,0,1-.163-.163V331.66a.81.81,0,0,0-.509-.761.828.828,0,0,0-.321-.065.814.814,0,0,0-.577.243l-.747.748a.164.164,0,0,1-.232,0l-.952-.952a.165.165,0,0,1,0-.232l.748-.746a.824.824,0,0,0-.583-1.407h-1.057a.163.163,0,0,1-.162-.163v-1.347a.163.163,0,0,1,.162-.163h1.057a.824.824,0,0,0,.583-1.406l-.748-.747a.166.166,0,0,1,0-.232l.952-.952a.165.165,0,0,1,.232,0l.747.747a.824.824,0,0,0,1.406-.582v-1.057a.163.163,0,0,1,.163-.162H473.9a.163.163,0,0,1,.163.162v1.057a.824.824,0,0,0,1.407.583l.748-.748a.164.164,0,0,1,.231,0l.952.953a.162.162,0,0,1,0,.231l-.747.747a.824.824,0,0,0,.582,1.407h1.057a.163.163,0,0,1,.163.162Zm0,0" transform="translate(-467.576 -322.002)" stroke="#000" stroke-miterlimit="10" stroke-width="0.6"/>
    <path id="Path_3322" data-name="Path 3322" d="M494.287,346.83a1.883,1.883,0,1,0,1.882,1.884,1.886,1.886,0,0,0-1.882-1.884Zm0,3.347a1.464,1.464,0,1,1,1.463-1.463,1.466,1.466,0,0,1-1.463,1.463Zm0,0" transform="translate(-488.638 -343.065)" stroke="#000" stroke-miterlimit="10" stroke-width="0.6"/>
  </g>
`]

export const ltQuality = [ "24.769 24.769", `
  <g id="Group_3826" data-name="Group 3826" transform="translate(-590.508 -617.432)">
    <circle id="Ellipse_444" data-name="Ellipse 444" cx="11.734" cy="11.734" r="11.734" transform="translate(591.158 618.082)" fill="none" stroke="#000" stroke-linejoin="round" stroke-width="1.3"/>
    <path id="Path_3323" data-name="Path 3323" d="M607.667,631.314l1.484-4.08a1.144,1.144,0,0,0-.389-1.306l-.143-.107a1.2,1.2,0,0,0-1.674.231l-4.543,5.941a2.425,2.425,0,0,0-.5,1.473v3.824a3.336,3.336,0,0,0,3.336,3.336h8.788a1.039,1.039,0,0,0,.734-1.774h0l.666-.5a1.039,1.039,0,0,0,0-1.662l-.666-.5.666-.5a1.039,1.039,0,0,0,0-1.662l-.666-.5.423-.529a1.039,1.039,0,0,0-.811-1.688Z" transform="translate(-5.98 -4.173)" fill="none" stroke="#000" stroke-linejoin="round" stroke-width="1.3"/>
  </g>
`]

export const ltMachine = [ "14.268 20.043", `
  <svg xmlns="http://www.w3.org/2000/svg" width="14.268" height="20.043" viewBox="0 0 14.268 20.043">
    <path id="Shape" d="M42.317,1H29.951A.951.951,0,0,0,29,1.951V5.077a.913.913,0,0,0,.476.813,1.027,1.027,0,0,0,.637.138c1.365,3.42,1.051,2.625,1.536,3.833a.951.951,0,0,0-.747.923V12.21a.951.951,0,0,0,.951.951h.476v1.427a.951.951,0,0,0,.951.951h.476v1.7a.476.476,0,0,0,.19.38L35.658,18.9v1.665a.476.476,0,0,0,.951,0V18.9l1.712-1.284a.476.476,0,0,0,.19-.38v-1.7h.476a.951.951,0,0,0,.951-.951V13.162h.476a.951.951,0,0,0,.951-.951V10.784a.951.951,0,0,0-.737-.923l1.536-3.833h.152a.951.951,0,0,0,.951-.951V1.951A.951.951,0,0,0,42.317,1ZM37.561,17l-1.427,1.07L34.707,17V15.54h2.854Zm2.854-4.79H33.28a.476.476,0,1,0,0,.951h5.707v1.427H33.28V13.162h.951a.476.476,0,1,0,0-.951H31.853V10.784h8.561Zm-.8-2.378H32.653l-1.522-3.8h9.988Zm2.7-4.756H29.951V1.951H42.317Z" transform="translate(-29 -1)"/>
  </svg>
`]

export const ltMachine2 = [ "23.733 23.223", `
  <g id="Group_2558" data-name="Group 2558" transform="translate(-12479 -12667.512)">
    <g id="Group_1054" data-name="Group 1054" transform="translate(12486.58 12681.188)">
      <g id="Group_1053" data-name="Group 1053" transform="translate(0)">
        <path id="Path_27" data-name="Path 27" d="M500.6,265.5a1.371,1.371,0,1,0,1.371,1.371A1.373,1.373,0,0,0,500.6,265.5Z" transform="translate(-499.229 -265.497)"/>
      </g>
    </g>
    <path id="Path_28" data-name="Path 28" d="M356.99-2.054a.437.437,0,0,0-.606-.121l-1.257.838-1.88-.313a1.37,1.37,0,0,0,.547-1.1,1.37,1.37,0,0,0-.547-1.1l1.88-.313,1.257.838a.435.435,0,0,0,.242.073.436.436,0,0,0,.364-.195.437.437,0,0,0-.121-.606l-1.4-.934a.437.437,0,0,0-.314-.067l-2.412.4a.9.9,0,0,0-.678.526h-.107a1.373,1.373,0,0,0-1.3.934h-1.6a1.373,1.373,0,0,0-1.3-.934H334.728a1.574,1.574,0,0,0-.715.227,1.364,1.364,0,0,0-.636.836,1.364,1.364,0,0,0,.141,1.041l5.81,11.563-.719,4.947h-.062a.9.9,0,0,0-.9.9v1.9h-.964a.437.437,0,0,0,0,.874h11.209a.437.437,0,0,0,0-.874h-.964V15.4a.9.9,0,0,0-.9-.9h-.062l-.724-5.007a2.976,2.976,0,0,0-2.4-2.68l-3.584-5.66,4.769,2.457a1.369,1.369,0,0,0,1.582-.235l1.011.521a1.372,1.372,0,0,0,.728,1.426l.415.214a1.369,1.369,0,0,0,1.582-.235l2.294,1.182a.9.9,0,1,0,.4-.777l-2.293-1.181A1.372,1.372,0,0,0,349,3.094l-.415-.214a1.369,1.369,0,0,0-1.582.235L346,2.594a1.373,1.373,0,0,0-.727-1.425l-4.938-2.544h7.422a1.373,1.373,0,0,0,1.3-.934h1.6a1.373,1.373,0,0,0,1.3.934h.107a.9.9,0,0,0,.678.526l2.412.4a.439.439,0,0,0,.072.006.437.437,0,0,0,.242-.073l1.4-.934A.437.437,0,0,0,356.99-2.054ZM346.018,15.37a.03.03,0,0,1,.03.03v1.9h-7.533V15.4a.03.03,0,0,1,.03-.03H344.5a.4.4,0,0,1,.12,0ZM344.36,9.557l.719,4.94h-.462a.39.39,0,0,1-.12,0h-5.012l.719-4.94a2.085,2.085,0,0,1,4.157,0Zm-2.619-2.748a2.985,2.985,0,0,0-2.09,1.561L334.273-2.453a.5.5,0,0,1,.18-.682.506.506,0,0,1,.652.135Zm3.127-4.863a.5.5,0,1,1-.455.884L338.078-.434l-.692-.941h1.037Zm2.885-4.194h-11.01l-.732-.994h11.742a.5.5,0,0,1,.5.5A.5.5,0,0,1,347.753-2.249Z" transform="translate(12145.669 12672.563)"/>
  </g>
`]

export const ltEBR = [ "24.623 23.812", `
  <g id="analytics" transform="translate(-2.476 0.282)">
    <path id="Path_2495" data-name="Path 2495" d="M32.582,14.865a.463.463,0,0,0-.244.894l3.972,1.082L35.1,19.515a1.8,1.8,0,0,0-.319-.033,1.876,1.876,0,1,0,1.158.42l1.279-2.813,5.317,1.45a.477.477,0,0,0,.124.016.463.463,0,0,0,.427-.284l2.141-5.086,4.382,1.18v.019A1.854,1.854,0,1,0,52.3,12.742l1.05-4.723,2.393-.6a.463.463,0,0,0-.224-.9l-1.946.486L54.3,3.72a1.863,1.863,0,1,0-.9-.2l-.832,3.743L46.25,8.838a.462.462,0,0,0-.315.27L44.684,12.08l-3.419-.921v-.019a1.854,1.854,0,1,0-3.012,1.433L36.7,15.987Zm2.2,7.4a.927.927,0,1,1,.927-.927A.927.927,0,0,1,34.777,22.263ZM53.313,1.873a.927.927,0,1,1,.927.927A.927.927,0,0,1,53.313,1.873Zm-.927,12.512a.927.927,0,1,1-.927-.927A.927.927,0,0,1,52.387,14.385ZM46.7,9.681l5.644-1.413L51.4,12.537a1.844,1.844,0,0,0-1.539.935l-4.269-1.149Zm-7.288.533a.927.927,0,1,1-.927.927A.927.927,0,0,1,39.411,10.214Zm-.319,2.748a1.821,1.821,0,0,0,.319.032,1.847,1.847,0,0,0,1.6-.942l3.306.89-1.935,4.6-4.781-1.3Zm0,0" transform="translate(-29.269 0)" stroke="#000" stroke-width="0.5"/>
  </g>
`]

export const ltPerformance = [ "18.77 12.3242", `
  <path id="Path_589" data-name="Path 589" d="M90.084,160v1.432h2.926l-8.477,8.477-3.223-3.223-4.625,4.625,1.013,1.013,3.612-3.612,3.223,3.223,9.49-9.49v2.926h1.432V160Z" transform="translate(-76.686 -160)"/>
`]

export const ltReporting = [ "20.12 17.554", `
  <g id="Rectangle_19953" data-name="Rectangle 19953" transform="translate(9.15 13.15)" fill="none" stroke="#000" stroke-width="1">
    <rect width="1.5" height="1.5" stroke="none"/>
    <rect x="0.5" y="0.5" width="0.5" height="0.5" fill="none"/>
  </g>
  <path id="Icon_material-report-problem" data-name="Icon material-report-problem" d="M1.5,19.254H20.32l-4.7-8.11L10.91,3Z" transform="translate(-0.85 -2.35)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
  <g id="Rectangle_19952" data-name="Rectangle 19952" transform="translate(9.15 6.15)" fill="none" stroke="#000" stroke-width="1">
    <rect width="1.5" height="6" stroke="none"/>
    <rect x="0.5" y="0.5" width="0.5" height="5" fill="none"/>
  </g>
`]

export const ltEnergy = [ "11.471 21.753", `
<svg xmlns="http://www.w3.org/2000/svg" width="11.471" height="21.753" viewBox="0 0 11.471 21.753">
  <g id="lighting" transform="translate(-120.999 0)">
    <path id="Path_2494" data-name="Path 2494" d="M121.336,21.677a.637.637,0,0,0,.817-.187l10.2-14.063a.637.637,0,0,0-.516-1.011h-2.7L132.381.964A.637.637,0,0,0,131.834,0h-7.648a.637.637,0,0,0-.618.483l-2.549,10.239a.637.637,0,0,0,.618.791h2.906l-3.5,9.379A.637.637,0,0,0,121.336,21.677ZM126.058,11.1a.637.637,0,0,0-.6-.86h-3.008l2.232-8.965h6.028l-3.249,5.452a.637.637,0,0,0,.547.964h2.575l-6.709,9.252Z"/>
  </g>
</svg>
`]


export const ltProcessing = [ "20.614 20.613", `
<svg xmlns="http://www.w3.org/2000/svg" width="20.614" height="20.613" viewBox="0 0 20.614 20.613">
  <g id="Group_3818" data-name="Group 3818" transform="translate(0.1 0.1)">
    <g id="Layer_1" transform="translate(0 0)">
      <path id="Path_3250" data-name="Path 3250" d="M22.281,16.948A9.355,9.355,0,0,1,6.294,26.156H9.253v-.851H5v4.253h.851V26.953a10.209,10.209,0,0,0,17.227-10.3Z" transform="translate(-3.299 -9.995)" fill="#5797a0" stroke="#5797a0" stroke-width="0.2"/>
      <path id="Path_3251" data-name="Path 3251" d="M15.46,6.1h4.253V1.851h-.851v2.6a10.21,10.21,0,0,0-17.227,10.3l.8-.3A9.355,9.355,0,0,1,18.419,5.253H15.46Z" transform="translate(-1 -1)" fill="#5797a0" stroke="#5797a0" stroke-width="0.2"/>
    </g>
  </g>
</svg>
`]

export const ltPending = [ "20.614 20.614", `
<svg xmlns="http://www.w3.org/2000/svg" width="20.614" height="20.614" viewBox="0 0 20.614 20.614">
  <g id="Group_3819" data-name="Group 3819" transform="translate(0.1 0.1)">
    <g id="Layer_1" transform="translate(0 0)">
      <path id="Path_3252" data-name="Path 3252" d="M11.207,1a10.17,10.17,0,0,0-3.55.635l.3.8a9.393,9.393,0,1,1-2.7,1.563V6.954H6.1V2.7H1.851v.851H4.456A10.207,10.207,0,1,0,11.207,1Z" transform="translate(-1 -1)" fill="#5797a0" stroke="#5797a0" stroke-width="0.2"/>
      <path id="Path_3253" data-name="Path 3253" d="M17.851,22.655H17v.851h6.8v-.851h-.851V21.2L21,19.253l1.95-1.95V15.851H23.8V15H17v.851h.851V17.3l1.95,1.95-1.95,1.95Zm.851-5.7v-1.1h3.4v1.1l-1.7,1.7Zm1.7,2.9,1.7,1.7v1.1H18.7v-1.1Z" transform="translate(-10.195 -9.046)" fill="#5797a0" stroke="#5797a0" stroke-width="0.2"/>
    </g>
  </g>
</svg>
`]

export const ltSent = [ "29.49 29.49", `
<svg xmlns="http://www.w3.org/2000/svg" width="29.49" height="29.49" viewBox="0 0 29.49 29.49">
  <g id="Group_3821" data-name="Group 3821" transform="translate(0 0)">
    <g id="Icon_feather-send" data-name="Icon feather-send" transform="translate(8.868 10.642)">
      <path id="Path_3248" data-name="Path 3248" d="M22.175,3,16.5,8.675" transform="translate(-11.857 -3)" fill="none" stroke="#5797a0" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
      <path id="Path_3249" data-name="Path 3249" d="M13.319,3,9.707,13.319,7.643,8.675,3,6.612Z" transform="translate(-3 -3)" fill="none" stroke="#5797a0" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
    </g>
    <g id="Group_3745" data-name="Group 3745" transform="translate(0 14.745) rotate(-45)">
      <g id="Rectangle_17651" data-name="Rectangle 17651" fill="none" stroke="#5797a0" stroke-width="1">
        <rect width="20.853" height="20.853" rx="10.426" stroke="none"/>
        <rect x="0.5" y="0.5" width="19.853" height="19.853" rx="9.926" fill="none"/>
      </g>
    </g>
  </g>
</svg>

`]

export const ltAcknowledge = [ "27.39 21.807", `
<svg xmlns="http://www.w3.org/2000/svg" width="27.39" height="21.807" viewBox="0 0 27.39 21.807">
  <g id="Group_3822" data-name="Group 3822" transform="translate(0.5 0.5)">
    <g id="Group_3751" data-name="Group 3751" transform="translate(0 0)">
      <g id="Icon_feather-send" data-name="Icon feather-send" transform="translate(3.69 5.869)">
        <path id="Path_3248" data-name="Path 3248" d="M226.735-55.358l-5.426,5.426" transform="translate(-216.873 55.358)" fill="none" stroke="#5797a0" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_3249" data-name="Path 3249" d="M225.939-55.358l-3.45,9.865-1.973-4.439L216.077-51.9Z" transform="translate(-216.077 55.358)" fill="none" stroke="#5797a0" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
      </g>
      <g id="Group_3749" data-name="Group 3749" transform="translate(0 0)">
        <g id="Path_3256" data-name="Path 3256">
          <path id="Path_3257" data-name="Path 3257" d="M231.459-53.172a9.806,9.806,0,0,0-2.865-6.214,9.822,9.822,0,0,0-6.988-2.894,9.815,9.815,0,0,0-6.986,2.9m0,0a9.815,9.815,0,0,0-2.9,6.988,9.815,9.815,0,0,0,2.9,6.988,9.814,9.814,0,0,0,6.986,2.894,8.966,8.966,0,0,0,5.236-1.5c.343-.243,1.312-1.05,1.312-1.05" transform="translate(-211.725 62.281)" fill="none" stroke="#5797a0" stroke-miterlimit="10" stroke-width="1"/>
        </g>
      </g>
      <g id="Group_3750" data-name="Group 3750" transform="translate(12.612 7.028)">
        <g id="Group_3748" data-name="Group 3748" transform="translate(0 0)">
          <g id="Rectangle_17651" data-name="Rectangle 17651">
            <path id="Path_3258" data-name="Path 3258" d="M229.933-50.659h0a5.269,5.269,0,0,1,7.451,0h0a5.269,5.269,0,0,1,0,7.451h0a5.269,5.269,0,0,1-7.451,0h0a5.268,5.268,0,0,1,0-7.451Z" transform="translate(-226.873 53.719)" fill="none"/>
            <rect id="Rectangle_19897" data-name="Rectangle 19897" width="9.596" height="9.596" rx="4.798" transform="translate(0 6.786) rotate(-45)" fill="none" stroke="#5797a0" stroke-width="1"/>
          </g>
        </g>
        <path id="Checkbox" d="M233.5-44.364l-2.273-2.273.461-.461,1.813,1.78,3-3,.461.461Z" transform="translate(-227.304 53.127)" fill="#5797a0" stroke="#5797a0" stroke-width="0.3"/>
      </g>
    </g>
  </g>
</svg>
`]

export const ltDeployok = [ "29.07 29.07", `
<svg xmlns="http://www.w3.org/2000/svg" width="29.07" height="29.07" viewBox="0 0 29.07 29.07">
  <g id="Group_3823" data-name="Group 3823" transform="translate(0 0)">
    <g id="Group_3755" data-name="Group 3755" transform="translate(0 14.535) rotate(-45)">
      <g id="Rectangle_17651" data-name="Rectangle 17651" fill="none" stroke="#5797a0" stroke-width="1">
        <rect width="20.556" height="20.556" rx="10.278" stroke="none"/>
        <rect x="0.5" y="0.5" width="19.556" height="19.556" rx="9.778" fill="none"/>
      </g>
    </g>
    <g id="Group_3756" data-name="Group 3756" transform="translate(16.949 6.464) rotate(45)">
      <path id="Path_3266" data-name="Path 3266" d="M5.815,12.224c-.087.122-.169.23-.244.342a.549.549,0,0,0-.076.161c-.075.273-.152,1.182-.213,1.458-.043.2-.131.484-.8.484-.5,0-1,0-1.491-.033a.62.62,0,0,1-.625-.586c-.022-.176-.085-.984-.127-1.157a.67.67,0,0,0-.209-.414c-.067-.054-.085-.168-.138-.279l-.736.434c-.185.11-.367.225-.552.333-.222.129-.409.04-.435-.215C.106,12.124.043,11.493,0,10.861a.482.482,0,0,1,.14-.317c.4-.422.816-.839,1.241-1.24a.579.579,0,0,0,.194-.531c-.077-.87-.124-1.743-.19-2.615A7.285,7.285,0,0,1,2.532,1.7,8.757,8.757,0,0,1,3.85,0c.2.233.411.448.593.683A9.53,9.53,0,0,1,6.007,3.568a7.721,7.721,0,0,1,.266,3.014c-.058.774-.113,1.548-.179,2.32a.347.347,0,0,0,.117.306c.44.431.869.872,1.311,1.3a.506.506,0,0,1,.161.461c-.06.544-.117,1.09-.147,1.636-.022.4-.207.542-.586.291-.343-.226-.7-.426-1.056-.637A.569.569,0,0,0,5.815,12.224ZM3.866.78c-.1.1-.159.148-.208.21A7.258,7.258,0,0,0,1.905,6.315q.259,2.849.509,5.7a.257.257,0,0,0,.221.251,4.662,4.662,0,0,0,2.407,0,.279.279,0,0,0,.229-.285c.138-1.5.3-2.988.421-4.485a19.607,19.607,0,0,0,.079-2.415A6.653,6.653,0,0,0,4.557,1.726C4.347,1.4,4.1,1.1,3.866.78ZM1.657,9.752c-.39.389-.746.744-1.1,1.1a.141.141,0,0,0-.038.1c.038.458.08.915.124,1.392.391-.235.76-.453,1.125-.677a.17.17,0,0,0,.079-.12C1.79,10.965,1.726,10.385,1.657,9.752Zm4.369,0c-.066.627-.129,1.2-.184,1.774a.181.181,0,0,0,.073.14c.364.224.732.442,1.124.676.043-.478.085-.935.123-1.393a.141.141,0,0,0-.04-.1C6.769,10.5,6.414,10.142,6.026,9.754Zm-1.1,3.109c-.376.025-.731.067-1.086.067s-.707-.042-1.075-.067c.036.143.08.927.106,1.076a.2.2,0,0,0,.209.191c.447.014.895.024,1.342.026.233.011.3-.1.314-.213.041-.271.075-.541.12-.8C4.869,13.052,4.9,12.959,4.922,12.864Z" transform="translate(0 0)" fill="#5797a0" stroke="#5797a0" stroke-width="0.3"/>
      <path id="Path_3268" data-name="Path 3268" d="M2.165.369a1.282,1.282,0,0,1,.007,1.808,1.294,1.294,0,0,1-1.8,0A1.274,1.274,0,0,1,2.165.369ZM.738.739a.747.747,0,0,0,0,1.072A.757.757,0,0,0,1.813,1.8.747.747,0,0,0,1.807.743.736.736,0,0,0,.738.739Z" transform="translate(2.57 4.133)" fill="#5797a0" stroke="#5797a0" stroke-width="0.3"/>
    </g>
  </g>
</svg>
`]

export const ltDeployNotok = [ "29.07 29.07", `
<svg xmlns="http://www.w3.org/2000/svg" width="29.07" height="29.07" viewBox="0 0 29.07 29.07">
  <g id="Group_3824" data-name="Group 3824" transform="translate(0 0)">
    <g id="Group_3757" data-name="Group 3757" transform="translate(0 14.535) rotate(-45)">
      <g id="Rectangle_17651" data-name="Rectangle 17651" fill="none" stroke="#5797a0" stroke-width="1">
        <rect width="20.556" height="20.556" rx="10.278" stroke="none"/>
        <rect x="0.5" y="0.5" width="19.556" height="19.556" rx="9.778" fill="none"/>
      </g>
    </g>
    <line id="Line_473" data-name="Line 473" x1="12.238" y1="15.735" transform="translate(8.305 6.882)" fill="none" stroke="#5797a0" stroke-width="1"/>
    <g id="Group_3758" data-name="Group 3758" transform="translate(16.949 6.464) rotate(45)">
      <path id="Path_3266" data-name="Path 3266" d="M5.815,12.224c-.087.122-.169.23-.244.342a.549.549,0,0,0-.076.161c-.075.273-.152,1.182-.213,1.458-.043.2-.131.484-.8.484-.5,0-1,0-1.491-.033a.62.62,0,0,1-.625-.586c-.022-.176-.085-.984-.127-1.157a.67.67,0,0,0-.209-.414c-.067-.054-.085-.168-.138-.279l-.736.434c-.185.11-.367.225-.552.333-.222.129-.409.04-.435-.215C.106,12.124.043,11.493,0,10.861a.482.482,0,0,1,.14-.317c.4-.422.816-.839,1.241-1.24a.579.579,0,0,0,.194-.531c-.077-.87-.124-1.743-.19-2.615A7.285,7.285,0,0,1,2.532,1.7,8.757,8.757,0,0,1,3.85,0c.2.233.411.448.593.683A9.53,9.53,0,0,1,6.007,3.568a7.721,7.721,0,0,1,.266,3.014c-.058.774-.113,1.548-.179,2.32a.347.347,0,0,0,.117.306c.44.431.869.872,1.311,1.3a.506.506,0,0,1,.161.461c-.06.544-.117,1.09-.147,1.636-.022.4-.207.542-.586.291-.343-.226-.7-.426-1.056-.637A.569.569,0,0,0,5.815,12.224ZM3.866.78c-.1.1-.159.148-.208.21A7.258,7.258,0,0,0,1.905,6.315q.259,2.849.509,5.7a.257.257,0,0,0,.221.251,4.662,4.662,0,0,0,2.407,0,.279.279,0,0,0,.229-.285c.138-1.5.3-2.988.421-4.485a19.607,19.607,0,0,0,.079-2.415A6.653,6.653,0,0,0,4.557,1.726C4.347,1.4,4.1,1.1,3.866.78ZM1.657,9.752c-.39.389-.746.744-1.1,1.1a.141.141,0,0,0-.038.1c.038.458.08.915.124,1.392.391-.235.76-.453,1.125-.677a.17.17,0,0,0,.079-.12C1.79,10.965,1.726,10.385,1.657,9.752Zm4.369,0c-.066.627-.129,1.2-.184,1.774a.181.181,0,0,0,.073.14c.364.224.732.442,1.124.676.043-.478.085-.935.123-1.393a.141.141,0,0,0-.04-.1C6.769,10.5,6.414,10.142,6.026,9.754Zm-1.1,3.109c-.376.025-.731.067-1.086.067s-.707-.042-1.075-.067c.036.143.08.927.106,1.076a.2.2,0,0,0,.209.191c.447.014.895.024,1.342.026.233.011.3-.1.314-.213.041-.271.075-.541.12-.8C4.869,13.052,4.9,12.959,4.922,12.864Z" transform="translate(0 0)" fill="#5797a0" stroke="#5797a0" stroke-width="0.3"/>
      <path id="Path_3268" data-name="Path 3268" d="M2.165.369a1.282,1.282,0,0,1,.007,1.808,1.294,1.294,0,0,1-1.8,0A1.274,1.274,0,0,1,2.165.369ZM.738.739a.747.747,0,0,0,0,1.072A.757.757,0,0,0,1.813,1.8.747.747,0,0,0,1.807.743.736.736,0,0,0,.738.739Z" transform="translate(2.57 4.133)" fill="#5797a0" stroke="#5797a0" stroke-width="0.3"/>
    </g>
  </g>
</svg>
`]

export const ltCanceled = [ "29.07 29.07", `
<svg xmlns="http://www.w3.org/2000/svg" width="29.07" height="29.07" viewBox="0 0 29.07 29.07">
  <g id="Group_3825" data-name="Group 3825" transform="translate(0 0)">
    <path id="Union_38" data-name="Union 38" d="M4.218,9.642V5.424H0V4.218H4.218V0H5.424V4.218H9.642V5.424H5.424V9.642Z" transform="translate(7.717 14.535) rotate(-45)" fill="#ff3434"/>
    <g id="Group_3789" data-name="Group 3789" transform="translate(0 14.535) rotate(-45)">
      <g id="Rectangle_17651" data-name="Rectangle 17651" fill="none" stroke="#ff3434" stroke-width="1">
        <rect width="20.556" height="20.556" rx="10.278" stroke="none"/>
        <rect x="0.5" y="0.5" width="19.556" height="19.556" rx="9.778" fill="none"/>
      </g>
    </g>
  </g>
</svg>

`]

export const ltCancel = [ "26.625 26.625", `
<svg xmlns="http://www.w3.org/2000/svg" width="26.625" height="26.625" viewBox="0 0 26.625 26.625">
  <g id="Group_3813" data-name="Group 3813" transform="translate(0 0)">
    <path id="Union_38" data-name="Union 38" d="M3.863,8.831V4.967H0v-1.1H3.863V0h1.1V3.863H8.831v1.1H4.968V8.831Z" transform="translate(7.068 13.312) rotate(-45)"/>
    <g id="Group_3789" data-name="Group 3789" transform="translate(0 13.312) rotate(-45)">
      <g id="Rectangle_17651" data-name="Rectangle 17651" fill="none" stroke="#000" stroke-width="1">
        <rect width="18.827" height="18.827" rx="9.413" stroke="none"/>
        <rect x="0.5" y="0.5" width="17.827" height="17.827" rx="8.913" fill="none"/>
      </g>
    </g>
  </g>
</svg>
`]

export const ltLeft = [ "13.882 25.905", `
<svg xmlns='http://www.w3.org/2000/svg' width='13.882' height='25.905' viewBox='0 0 13.882 25.905'><path id='Icon_metro-chevron-thin-left' data-name='Icon metro-chevron-thin-left' d='M24,28.408c0-.012-1.488,1.536-1.495,1.51l-12.077-12.2a1.074,1.074,0,0,1,0-1.51L22.5,4.013c.038,0,1.508,1.5,1.495,1.51L12.982,16.967,24,28.408Z' transform='translate(-10.115 -4.013)'/></svg>
`]

