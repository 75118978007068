import { LocalStorage } from 'ttl-localstorage';
import LZString from 'lz-string';

const axios = require('axios');

const baseUrl = window.PRODTRACK_CONFIGS.API.HOST || "http://localhost:3000";
const timeout = window.PRODTRACK_CONFIGS.API.TIMEOUT || 1000;
const ENDPOINTS = window.PRODTRACK_CONFIGS.API.ENDPOINTS;
const MAX_ACL_ARRAY_SIZE = 250;

LocalStorage.timeoutInSeconds = 300;

// declare an ajax request's cancelToken (globally)
let ajaxRequest = {};
let widgetKeys = {};

/**
 * API abstraction
 * 
 */
class ApiController {
  constructor() {

    this.headers = {};
    this.token = false;
    this.profile = false;
    this.props = {};
    this.axiosInstance = false;
    
    this.setState = function() {};

    LocalStorage.runGarbageCollector();

  }

  /**
   * Cancel all pending axios requests for a given widget
   * 
   * @param {String} widgetId 
   * @param {String} key 
   */
  cancelToken(widgetId, key) {
    
    // Get keys created for the widget
    let _widgetKeys = widgetKeys[widgetId];

    if(_widgetKeys) {

      // Iterate widget axios requests keys
      for(let _key in _widgetKeys) {
        
        if(typeof key !== "undefined") {

          if(_key === key) {

            // cancel  previous ajax if exists
            if (ajaxRequest[_key]) {
              ajaxRequest[_key].cancel(); 
            }
            delete widgetKeys[widgetId][_key];
            break;

          }//if

        }//if
        else {
          
          // cancel  previous ajax if exists
          if (ajaxRequest[_key]) {
            ajaxRequest[_key].cancel(); 
          }
          delete widgetKeys[widgetId][_key];

        }//else

        // creates a new token for upcomming ajax (overwrite the previous one)
        //ajaxRequest[_key] = axios.CancelToken.source();

      }//for

    }//if

  }

  /**
   * Create axios request cancel token
   * 
   * @param {String} widgetId 
   * @param {String} key 
   */
   createToken(widgetId, key) {
    
    if(typeof widgetKeys[widgetId] === "undefined") {
      widgetKeys[widgetId] = {};
    }//if

    // Cancel existing cancel token
    this.cancelToken(widgetId, key);

    // creates a new token for upcomming ajax (overwrite the previous one)
    widgetKeys[key] = true;
    ajaxRequest[key] = axios.CancelToken.source();

  }

  /**
   * Set current headers object
   * 
   * @param {Object} headers 
   */
  setHeaders(headers) {
      
    this.headers = headers;

  }

  /**
   * Set Authorization access token
   * 
   * @param {String} token 
   */
  setToken(token) {

    if(token) {
      this.headers['Authorization'] = 'Bearer '+token;
    }//if

    this.token = token;

  }

  /**
   * Set current logged profile
   * 
   * @param {Object} profile 
   */
  setProfile(profile) {

    this.profile = profile;

  }

  /**
   * Set current props
   * If accessToken and profile inside props, call corresponding set functions
   * 
   * @param {Object} props 
   */
  setProps(props) {

    if('accessToken' in props) this.setToken(props['accessToken']);
    if('profile' in props) this.setProfile(props['profile']);

    this.props = props;

  }

  /**
   * Set setState function callback
   * 
   * @param {function} setState 
   */
  setStateCallback(setState) {

    this.setState = setState;

  }

  /**
   * Create an axios instance
   */
  createInstance() {

    /**
     * Since we have a token check if its valid
     */
    this.axiosInstance = axios.create({
      baseURL: baseUrl,
      timeout: timeout,
      headers: this.headers
    });
  
  }

  /**
   * Return a list of all keys in cache
   */
  cacheList() {

    return LocalStorage.keys();

  }

  /**
   * Flush all data in cache
   */
  cacheFlush() {

    LocalStorage.clear();

  }

  /**
   * Delete a key from cache
   * 
   * @param {String|Array} path 
   */
  cacheDelete(path) {

    if(typeof path == "object") {

      let resp = false;

      for(let i=0; i<path.length; i++) {

        let key = LZString.compress(path[i]+'_'+JSON.stringify(this.headers));
        resp = LocalStorage.removeKey(key);
  
      }//for

      return resp;

    }//if
    else {
    
      let key = LZString.compress(path+'_'+JSON.stringify(this.headers));
      return LocalStorage.removeKey(key);

    }//else

  }

  /**
   * Change the ttl of a key in cache
   * 
   * @param {String} path 
   * @param {Integer} ttl 
   */
  cacheTtl(path, ttl) {

    //let key = LZString.compress(path+'_'+JSON.stringify(this.headers));
    //LocalStorage.ttl( key, ttl )

  }

  removeKeysOfEndpoint(endpoint) {

    let keys = this.cacheList();
    keys.forEach((key) => {

      let parsed = LZString.decompress(key);

      //console.log(key, parsed, endpoint);

      if(parsed && parsed.indexOf(parsed) >= 0) {

        LocalStorage.removeKey(key);

      }//if

    });
    
  }

  /**
   * Convert an array into a tree structure
   * 
   * @param {Array} list
   */
  linksToTree(list) {

    var map = {}, node, roots = [], i;
    
    if(list) {
      
      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
      }
      
      for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (typeof node.parent !== "undefined" && list[map[node.parent]] && list[map[node.parent]] !== "") {
          // if you have dangling branches check that map[node.parentId] exists
          list[map[node.parent]].children.push(node);
        } else {
          roots.push(node);
        }
      }

    }//if

    return roots;

  }

  treeToLinks(node, children, paths, params, viewsMap, parsedLinks) {

    let nodeParams = viewsMap[node.view] ? viewsMap[node.view].params : [];

    //console.log('treeToLinks', node.name, node.path, nodeParams, children, paths, params);

    // Remove tree path from node
    let _nodePath = node.path ? node.path.replace(/\/$/, '').replace(paths.join('/').replace(/\/\//g, '/'), '') : false;
    let _paths = paths.concat(_nodePath ? [_nodePath] : []);
    let _params = params.concat(nodeParams ? nodeParams : []);

    let leaf = {
      id: node.id,
      icon: node.icon,
      name: node.name,
      menu: node.menu,
      acls: node.acls,
      created: node.created,
      createdby: node.createdby,
      deleted: node.deleted,
      entities: node.entities,
      entity: node.entity,
      modified: node.modified,
      modifiedby: node.modifiedby,
      order: node.order,
      parent: node.parent,
      tenant: node.tenant,
      view: node.view,
      visible: node.visible,
      responsive: node.responsive,
      originalPath: node.path,
    };
    leaf.path = _paths.join('/').replace(/\/\//g, '/');
    leaf.params = _params;

    //console.log('    >', _nodePath, leaf.path, _paths, _params);

    parsedLinks.push(leaf);
    
    if(!children || !children.length) {

      return;

    }//if
    
    for(let i=0, n=children.length; i<n; i++) {

      this.treeToLinks(
        children[i], 
        children[i].children,
        _paths, 
        _params, 
        viewsMap, 
        parsedLinks
      );

    }//for

    return;

  }

  /**
   * Authenticate user
   * 
   * @param {String} email 
   * @param {String} password 
   */
  async login(email, password) {

    if(!this.axiosInstance) this.createInstance();

    var token, profile, error = null;

    try {
      
      /*
      console.log({
        method: 'POST',
        url: '/login',
        data: {
          email: email,
          password: password
        }
      });
      */
      
      let data = {
        password: password
      };

      if(email.indexOf('@') >= 0) {
        data['email'] = email;
      }//if
      else {
        data['username'] = email;
      }//else

      let response = await this.axiosInstance({
        method: 'POST',
        url: '/login',
        validateStatus: false,
        data: data
      });
      
      //console.log(response);

      if(response.status === 200) {
        
        this.setToken(response.data.token);
        return await this.getProfile();

      }//if
      else {

        // FAILED TO AUTHENTICATE
        error = response;

      }//else

    } catch(_error) {

      console.log(_error);
      error = _error;

    }

    return { error, token, profile };

  }

  /**
   * Get the profile roles
   * 
   * @param {Array} _roles
   */
  async _getProfileRoles(_roles, createGet) {

    if(!this.axiosInstance) this.createInstance();

    let params = {
      filter: {
        where: {
          id: {
            inq: _roles
          }
        }
      }
    };

    if(createGet) {

      return this.createGet('API', ENDPOINTS['ROLES'], params, {})

    }//if

    var roles, error = null;

    try {
      
      let response = await this.axiosInstance({
        method: 'GET',
        url: ENDPOINTS['ROLES'],
        headers: this.headers,
        validateStatus: false,
        params: params
      });
      
      //console.log(response);
      
      if(response.status === 200) {
        
        roles = response.data;

        return { error, roles }

      }//if
      else {
  
        // FAILED TO GET PROFILE ROLES
        error = response;
  
      }//else

    } catch(_error) {
      
      console.log(_error);
      error = _error;

    }
    
    return { error, roles };

  }
  async getProfileRoles(_roles, createGet) {

    if(createGet) {

      return this._getProfileRoles(_roles, createGet);

    }//if

    let key = LZString.compress(ENDPOINTS['ROLES']+'_'+JSON.stringify(this.headers));
    let ttl = 60 * 60 * 24 * 31;
    //let ttl = 10000;

    let error, roles;

    let value = await LocalStorage.get(key);
    
    if(typeof value != "undefined" && value !== null) {

      value = JSON.parse(LZString.decompress(value));

      error = value['error'];
      roles = value['roles'];

      return { error, roles };

    }
    
    let resp = await this._getProfileRoles(_roles, createGet);
    error = resp['error'];
    roles = resp['roles'];

    if(!error && roles) {

      await LocalStorage.put(key, LZString.compress(JSON.stringify(resp)), ttl);

    }//if

    return { error, roles };

  }

  /**
   * Get the profile entity
   * 
   * @param {Object} _entity
   */
  async _getProfileEntity(_entity, createGet) {
    
    if(!this.axiosInstance) this.createInstance();

    if(createGet) {

      return this.createGet('API', '/tenant-entities/'+_entity, {}, {})

    }//if

    var entity, error = null;

    try {
      
      let response = await this.axiosInstance({
        method: 'GET',
        url: '/tenant-entities/'+_entity,
        headers: this.headers,
        validateStatus: false,
      });
      
      //console.log(response);
      
      if(response.status === 200) {
        
        entity = response.data;

        return { error, entity }

      }//if
      else {
  
        // FAILED TO GET PROFILE ROLES
        error = response;
  
      }//else

    } catch(_error) {
      
      console.log(_error);
      error = _error;

    }
    
    return { error, entity };

  }
  async getProfileEntity(_entity, createGet) {

    if(createGet) {

      return this._getProfileEntity(_entity, createGet);

    }//if

    let key = LZString.compress('/tenant-entities_'+JSON.stringify(this.headers));
    let ttl = 60 * 60 * 24 * 31;
    //let ttl = 10000;

    let error, entity;

    let value = await LocalStorage.get(key);
    
    if(typeof value != "undefined" && value !== null) {

      value = JSON.parse(LZString.decompress(value));

      error = value['error'];
      entity = value['entity'];

      return { error, entity };

    }
    
    let resp = await this._getProfileEntity(_entity, createGet);
    error = resp['error'];
    entity = resp['entity'];

    if(!error && entity) {

      await LocalStorage.put(key, LZString.compress(JSON.stringify(resp)), ttl);

    }//if

    return { error, entity };

  }

  /**
   * Get the profile entities
   * 
   * @param {Array} _entities
   */
  async _getProfileEntities(_entities, createGet) {

    if(!this.axiosInstance) this.createInstance();

    let params = {
      filter: {
        where: {
          id: {
            inq: _entities
          }
        }
      }
    };

    if(createGet) {

      return this.createGet('API', '/tenant-entities', params, {})

    }//if

    var entities, error = null;

    try {
      
      let response = await this.axiosInstance({
        method: 'GET',
        url: '/tenant-entities',
        headers: this.headers,
        validateStatus: false,
        params: params
      });
      
      //console.log(response);
      
      if(response.status === 200) {
        
        entities = response.data;

        return { error, entities }

      }//if
      else {
  
        // FAILED TO GET PROFILE ROLES
        error = response;
  
      }//else

    } catch(_error) {
      
      console.log(_error);
      error = _error;

    }
    
    return { error, entities };

  }
  async getProfileEntities(_entities, createGet) {

    if(createGet) {

      return this._getProfileEntities(_entities, createGet);

    }//if

    let key = LZString.compress('/tenant-entities_all_'+JSON.stringify(this.headers));
    let ttl = 60 * 60 * 24 * 31;
    //let ttl = 10000;

    let error, entities;

    let value = await LocalStorage.get(key);
    
    if(typeof value != "undefined" && value !== null) {

      value = JSON.parse(LZString.decompress(value));
      
      error = value['error'];
      entities = value['entities'];

      return { error, entities };

    }
    
    let resp = await this._getProfileEntities(_entities, createGet);
    error = resp['error'];
    entities = resp['entities'];

    if(!error && entities) {

      await LocalStorage.put(key, LZString.compress(JSON.stringify(resp)), ttl);

    }//if

    return { error, entities };

  }

  /**
   * Get the current authenticated user profile
   */
  async _getProfile() {

    if(!this.axiosInstance) this.createInstance();
    
    //let key = LZString.compress(ENDPOINTS['PROFILE']);

    var token, profile, error = null;

    try {
      
      /*
      console.log({
        method: 'GET',
        url: ENDPOINTS['PROFILE'],
        headers: this.headers
      });
      */

      /*
      let filter = {
        limit: 1,
        where: {
        },
        include: [
            {
                'relation': 'entityRelation'
            }
        ]
      };
      */

      let response = await this.axiosInstance({
        method: 'GET',
        url: ENDPOINTS['PROFILE'],
        headers: this.headers,
        validateStatus: false,
      });
      
      //console.log(response);
        
      if(response.status === 200) {
        
        token = this.token;
        profile = response.data;
        this.profile = profile;
        
        let reqs = [
          this.getProfileRoles(profile.roles, true),
          this.getProfileEntity(profile.entity, true),
          this.getProfileEntities(profile.entities, true),
        ];
        // Query API in parallel
        let responses = await this.all('API', reqs);

        //console.log('getProfile', responses);

        let roles = responses[0].data;
        let entity = responses[1].data;
        let entities = responses[2].data;

        /*
        let getResp = await this.getProfileRoles(profile.roles, true);
        let roles = getResp['roles'];
        //console.log('getProfile:', getResp['error'], getResp['roles']);

        let entityResp = await this.getProfileEntity(profile.entity, true);
        let entity = entityResp['entity'];
        //console.log('getProfile:', entityResp['error'], entityResp['entity']);
        
        let entitiesResp = await this.getProfileEntities(profile.entities, true);
        let entities = entitiesResp['entities'];
        */

        if(roles) {

          profile.roles = roles;
          this.profile.roles = roles;

        }//if

        if(entity) {
          profile.entity = entity;
          this.profile.entity = entity;
        }//if

        if(entities) {
          profile.entities = entities;
          this.profile.entities = entities;
        }//if

        return { error, token, profile }

      }//if
      else {
  
        // FAILED TO GET PROFILE
        error = response;
  
      }//else

      //ajaxRequest[key] = false;

    } catch(_error) {
      
      console.log(_error);
      error = _error;

    }
    
    return { error, token, profile };

  }
  async getProfile() {

    let key = LZString.compress(ENDPOINTS['PROFILE']+'_'+JSON.stringify(this.headers));
    let ttl = 60 * 60 * 24 * 31;
    //let ttl = 10000;

    let error, token, profile;

    let value = await LocalStorage.get(key);
    
    if(typeof value != "undefined" && value !== null) {

      value = JSON.parse(LZString.decompress(value));
      
      error = value['error'];
      token = value['token'];
      profile = value['profile'];

      return { error, token, profile };

    }
    
    let resp = await this._getProfile();
    error = resp['error'];
    token = resp['token'];
    profile = resp['profile'];

    if(!error && token && profile) {

      await LocalStorage.put(key, LZString.compress(JSON.stringify(resp)), ttl);

    }//if

    return { error, token, profile };

  }

  /**
   * Get the link acl available to the current user
   * 
   * @param {String} link 
   */
  async getLinkAcls(link) {

    if(!this.axiosInstance) this.createInstance();

    var acls, error = null;

    try {
      
      let roleIds = [];
      for(let i=0; i<this.profile.roles.length; i++) {
        roleIds.push(this.profile.roles[i].id);
      }

      if(typeof link == "object") {

        let acls = [];

        while(link.length > 0) {

          let arr = link.splice(0, MAX_ACL_ARRAY_SIZE);

          let response = await this.axiosInstance({
            method: 'GET',
            url: ENDPOINTS['LINKACLS'],
            headers: this.headers,
            validateStatus: false,
            params: {
              filter: {
                where: {
                  id: {
                    inq: arr
                  }
                }
              }
            }
          });
          
          //console.log(response);
          
          if(response.status === 200) {
            
            acls = acls.concat(response.data);
  
          }//if
          else {
      
            // FAILED TO GET LINK ACLS
            error = response;
      
          }//else
  
        }//while

        return { error, acls }
  
      }//if
      else {

        let response = await this.axiosInstance({
          method: 'GET',
          url: ENDPOINTS['LINKACLS'],
          headers: this.headers,
          validateStatus: false,
          params: {
            filter: {
              where: {
                id: link
              }
            }
          }
        });
        
        //console.log(response);
        
        if(response.status === 200) {
          
          acls = response.data;

          return { error, acls }

        }//if
        else {
    
          // FAILED TO GET LINK ACLS
          error = response;
    
        }//else

      }//else

    } catch(_error) {
      
      console.log(_error);
      error = _error;

    }
    
    return { error, acls };

  }

  /**
   * Get the menu acl available to the current user
   * 
   * @param {String} menu 
   */
  async getMenuAcls(menu) {

    if(!this.axiosInstance) this.createInstance();

    var acls, error = null;

    try {
      
      /*
      console.log({
        method: 'GET',
        url: ENDPOINTS['MENUACLS'],
        headers: this.headers,
        params: {
          filter: {
            where: {
              menu: menu,
              role: {
                inq: this.profile.roles
              }
            }
          }
        }
      });
      */

      let roleIds = [];
      for(let i=0; i<this.profile.roles.length; i++) {
        roleIds.push(this.profile.roles[i].id);
      }

      if(typeof menu == "object") {

        let acls = [];

        while(menu.length > 0) {

          let arr = menu.splice(0, MAX_ACL_ARRAY_SIZE);

          let response = await this.axiosInstance({
            method: 'GET',
            url: ENDPOINTS['MENUACLS'],
            headers: this.headers,
            validateStatus: false,
            params: {
              filter: {
                where: {
                  id: {
                    inq: arr
                  }
                }
              }
            }
          });

          //console.log(response);
          
          if(response.status === 200) {
            
            acls = acls.concat(response.data);
  
          }//if
          else {
      
            // FAILED TO GET MENU ACLS
            error = response;
      
          }//else
  
        }//while

        return { error, acls }
  
      }//if
      else {

        let response = await this.axiosInstance({
          method: 'GET',
          url: ENDPOINTS['MENUACLS'],
          headers: this.headers,
          validateStatus: false,
          params: {
            filter: {
              where: {
                id: menu
              }
            }
          }
        });
        
        //console.log(response);
        
        if(response.status === 200) {
          
          acls = response.data;

          return { error, acls }

        }//if
        else {
    
          // FAILED TO GET MENU ACLS
          error = response;
    
        }//else

      }//else

    } catch(_error) {
      
      console.log(_error);
      error = _error;

    }
    
    return { error, acls };

  }

  /**
   * Get the view acl available to the current user
   * 
   * @param {String} view 
   */
  async getViewAcls(view) {

    if(!this.axiosInstance) this.createInstance();

    var acls, error = null;

    try {
      
      /*
      console.log({
        method: 'GET',
        url: ENDPOINTS['VIEWACLS'],
        headers: this.headers,
        params: {
          filter: {
            where: {
              view: view,
              role: {
                inq: this.profile.roles
              }
            }
          }
        }
      });
      */
      let roleIds = [];
      for(let i=0; i<this.profile.roles.length; i++) {
        roleIds.push(this.profile.roles[i].id);
      }

      if(typeof view == "object") {

        let acls = [];

        while(view.length > 0) {

          let arr = view.splice(0, MAX_ACL_ARRAY_SIZE);

          let response = await this.axiosInstance({
            method: 'GET',
            url: ENDPOINTS['VIEWACLS'],
            headers: this.headers,
            validateStatus: false,
            params: {
              filter: {
                where: {
                  id: {
                    inq: arr
                  }
                }
              }
            }
          });

          //console.log(response);
          
          if(response.status === 200) {
            
            acls = acls.concat(response.data);
  
          }//if
          else {
      
            // FAILED TO GET VIEW ACLS
            error = response;
      
          }//else
  
        }//while

        return { error, acls }
  
      }//if
      else {

        let response = await this.axiosInstance({
          method: 'GET',
          url: ENDPOINTS['VIEWACLS'],
          headers: this.headers,
          validateStatus: false,
          params: {
            filter: {
              where: {
                id: view
              }
            }
          }
        });
        
        /*
        console.log({
          filter: {
            where: {
              id: typeof view == "object" ? {
                inq: view
              } : view
            }
          }
        }, response);
        */
        
        if(response.status === 200) {
          
          acls = response.data;

          return { error, acls }

        }//if
        else {
    
          // FAILED TO GET VIEW ACLS
          error = response;
    
        }//else

      }//else

    } catch(_error) {
      
      console.log(_error);
      error = _error;

    }
    
    return { error, acls };

  }

  /**
   * Get the menu links available to the current user
   * 
   * @param {String} menu 
   */
  async getMenuLinks(menu) {

    if(!this.axiosInstance) this.createInstance();

    let key = LZString.compress(ENDPOINTS['LINKS']);
    
    var links, views, viewsMap = {}, error = null;

    try {
      
      /*
      console.log({
        method: 'GET',
        url: ENDPOINTS['LINKS'],
        headers: this.headers,
        params: {
          filter: {
            order: ['order ASC'],
            where: {
              menu: menu
            }
          }
        }
      });
      */

      let response = await this.axiosInstance({
        method: 'GET',
        url: ENDPOINTS['LINKS'],
        headers: this.headers,
        validateStatus: false,
        params: {
          filter: {
            order: ['order ASC'],
            where: {
              menu: menu
            }
          }
        }
      });
      
      //console.log(response);
        
      if(response.status === 200) {
        
        links = response.data;
        let linkIds = [];

        // TODO: New link logic
        let viewIds = [];

        for(let i=0, n=links.length; i<n; i++) {

          viewIds.push(links[i].view);

          if(viewIds.length > 100) {

            // Get Views
            let viewsResponse = await this.axiosInstance({
              cancelToken: ajaxRequest[key].token,
              method: 'GET',
              url: ENDPOINTS['VIEWS'],
              headers: this.headers,
              validateStatus: false,
              params: {
                filter: {
                  fields: {
                    'id': true, 
                    'name': true, 
                    'concurrent': true, 
                    'params': true, 
                    'categories': true, 
                    'acls': true, 
                    'created': true
                  },
                  where: {
                    id: {
                      inq: viewIds
                    }
                  }
                }
              }
            });
            
            if(viewsResponse.status === 200) {

              views = viewsResponse.data;

              for(let i=0, n=views.length; i<n; i++) {

                viewsMap[views[i].id] = views[i];

              }//for

            }//if

            viewIds.length = 0;

          }//if

        }//for

        if(viewIds.length) {

          // Get Views
          let viewsResponse = await this.axiosInstance({
            //cancelToken: ajaxRequest[vkey].token,
            method: 'GET',
            url: ENDPOINTS['VIEWS'],
            headers: this.headers,
            validateStatus: false,
            params: {
              filter: {
                fields: {
                  'id': true, 
                  'name': true, 
                  'concurrent': true, 
                  'params': true, 
                  'categories': true, 
                  'acls': true, 
                  'created': true
                },
                where: {
                  id: {
                    inq: viewIds
                  }
                }
              }
            }
          });
          
          if(viewsResponse.status === 200) {

            views = viewsResponse.data;

            for(let i=0, n=views.length; i<n; i++) {

              viewsMap[views[i].id] = views[i];

            }//for

          }//if

        }//if

        let roots = this.linksToTree(links);

        let parsedLinks = [];

        for(let i=0, n=roots.length; i<n; i++) {

          this.treeToLinks(
            roots[i],
            roots[i].children,
            [],
            [],
            viewsMap,
            parsedLinks
          );

        }//for

        //console.log('tree', roots, 'parsedLinks', parsedLinks);

        links = parsedLinks;
        // <----------- TODO: New link logic

        /*
        for(var i=0, n=links.length; i<n; i++) {

          if(links[i].acls && links[i].acls.length > 0) {
            linkIds = linkIds.concat(links[i].acls);
          }//if
          
        }//for
        */

        if(linkIds.length) {
          
          let getResp = await this.getLinkAcls(linkIds);
          let error_acl = getResp['error'];
          let acls = getResp['acls'];
          if(!error_acl && acls) {
            
            for(let i=0, n=links.length; i<n; i++) {

              let newAcls = [];

              if(links[i].acls && links[i].acls.length > 0) {
                
                for(let j=0, o=links[i].acls.length; j<o; j++) {
                  
                  for(let k=0; k<acls.length; k++) {

                    if(links[i].acls[j] === acls[k].id) {

                      newAcls.push(acls[k]);

                    }//if

                  }//for

                }//for

              }//if
              
              links[i].acls = newAcls;

            }//for

          }//if

        }//if

        //console.log('links', links, acls);
          
        return { error, links }

      }//if
      else {
  
        // FAILED TO GET MENU LINKS
        error = response;
  
      }//else

    } catch(_error) {
      
      console.log(_error);
      error = _error;

    }
    
    return { error, links };

  }

  /**
   * Get the links available to the current user
   */
  async getLinks() {

    if(!this.axiosInstance) this.createInstance();
    
    let key = LZString.compress(ENDPOINTS['LINKS']);

    this.createToken(key, key);

    var links, views, viewsMap = {}, error = null;

    try {
      
      let response = await this.axiosInstance({
        cancelToken: ajaxRequest[key].token,
        method: 'GET',
        url: ENDPOINTS['LINKS'],
        headers: this.headers,
        validateStatus: false,
        params: {
          filter: {
            order: ['order ASC'],
          }
        }
      });
      
      //console.log(response);
        
      if(response.status === 200) {
        
        links = response.data;
        let linkIds = [];

        // TODO: New link logic
        let viewIds = [];

        for(let i=0, n=links.length; i<n; i++) {

          viewIds.push(links[i].view);

          if(viewIds.length > 100) {

            // Get Views
            let viewsResponse = await this.axiosInstance({
              cancelToken: ajaxRequest[key].token,
              method: 'GET',
              url: ENDPOINTS['VIEWS'],
              headers: this.headers,
              validateStatus: false,
              params: {
                filter: {
                  fields: {
                    'id': true, 
                    'name': true, 
                    'concurrent': true, 
                    'params': true, 
                    'categories': true, 
                    'acls': true, 
                    'created': true
                  },
                  where: {
                    id: {
                      inq: viewIds
                    }
                  }
                }
              }
            });
            
            if(viewsResponse.status === 200) {

              views = viewsResponse.data;

              for(let i=0, n=views.length; i<n; i++) {

                viewsMap[views[i].id] = views[i];

              }//for

            }//if

            viewIds.length = 0;

          }//if

        }//for

        if(viewIds.length) {

          // Get Views
          let viewsResponse = await this.axiosInstance({
            cancelToken: ajaxRequest[key].token,
            method: 'GET',
            url: ENDPOINTS['VIEWS'],
            headers: this.headers,
            validateStatus: false,
            params: {
              filter: {
                fields: {
                  'id': true, 
                  'name': true, 
                  'concurrent': true, 
                  'params': true, 
                  'categories': true, 
                  'acls': true, 
                  'created': true
                },
                where: {
                  id: {
                    inq: viewIds
                  }
                }
              }
            }
          });
          
          if(viewsResponse.status === 200) {

            views = viewsResponse.data;

            for(let i=0, n=views.length; i<n; i++) {

              viewsMap[views[i].id] = views[i];

            }//for

          }//if

        }//if

        let roots = this.linksToTree(links);

        let parsedLinks = [];

        for(let i=0, n=roots.length; i<n; i++) {

          this.treeToLinks(
            roots[i],
            roots[i].children,
            [],
            [],
            viewsMap,
            parsedLinks
          );

        }//for

        //console.log('tree', roots, 'parsedLinks', parsedLinks);

        links = parsedLinks;
        // <----------- TODO: New link logic

        // TODO: Old link logic
        /*
        for(let i=0, n=links.length; i<n; i++) {

          if(links[i].acls && links[i].acls.length > 0) {
            linkIds = linkIds.concat(links[i].acls);
          }//if
          
        }//for
        */
        // <----------- TODO: Old link logic

        let getResp = await this.getLinkAcls(linkIds);
        let error_acl = getResp['error'];
        let acls = getResp['acls'];
        if(!error_acl && acls) {
          
          for(let i=0, n=links.length; i<n; i++) {

            let newAcls = [];

            if(links[i].acls && links[i].acls.length > 0) {
              
              for(let j=0, o=links[i].acls.length; j<o; j++) {
                
                for(let k=0; k<acls.length; k++) {

                  if(links[i].acls[j] === acls[k].id) {

                    newAcls.push(acls[k]);

                  }//if

                }//for

              }//for

            }//if
            
            links[i].acls = newAcls;

          }//for

        }//if

        //console.log('links', links, acls);

        return { error, links }

      }//if
      else {
  
        // FAILED TO GET MENU LINKS
        error = response;
  
      }//else

      ajaxRequest[key] = false;

    } catch(_error) {
      
      console.log(_error);
      error = _error;

    }
    
    return { error, links };

  }
  async _getLinks() {

    let key = LZString.compress(ENDPOINTS['LINKS']+'_'+JSON.stringify(this.headers));
    let ttl = 60 * 60 * 24 * 31;
    //let ttl = 600;

    let error, links;

    let value = await LocalStorage.get(key);
    
    //console.log('getLinks CACHE:', value);

    if(typeof value != "undefined" && value !== null) {

      value = JSON.parse(LZString.decompress(value));
      
      error = value['error'];
      links = value['links'];

      return { error, links };

    }
    
    let resp = await this._getLinks();
    error = resp['error'];
    links = resp['links'];

    //console.log('getLinks API:', error, links);

    if(!error && links) {
      await LocalStorage.put(key, LZString.compress(JSON.stringify(resp)), ttl);
      //console.log('getLinks SAVE CACHE:', success);
    }//if

    return { error, links };

  }

  /**
   * Get the menus available to the current user
   */
  async getMenus() {

    if(!this.axiosInstance) this.createInstance();
    
    let key = LZString.compress(ENDPOINTS['MENUS']);

    this.createToken(key, key);

    var menus, error = null;

    try {
      
      /*
      console.log({
        method: 'GET',
        url: ENDPOINTS['MENUS'],
        headers: this.headers
      });
      */

      let response = await this.axiosInstance({
        cancelToken: ajaxRequest[key].token,
        method: 'GET',
        url: ENDPOINTS['MENUS'],
        headers: this.headers,
        validateStatus: false,
      });
      
      //console.log(response);
        
      if(response.status === 200) {
        
        menus = response.data;
        for(var i=0, n=menus.length; i<n; i++) {

          let getResp = await this.getMenuLinks(menus[i].id);
          let error_links = getResp['error'];
          let links = getResp['links'];

          if(!error_links) {
            menus[i].links = links;
          }//if

          getResp = await this.getMenuAcls(menus[i].acls);
          let error_acls = getResp['error'];
          let acls = getResp['acls'];

          if(!error_acls) {
            menus[i].acls = acls;
          }//if

        }//for

        return { error, menus }

      }//if
      else {
  
        // FAILED TO GET MENUS
        error = response;
  
      }//else

      ajaxRequest[key] = false;
      
    } catch(_error) {
      
      console.log(_error);
      error = _error;

    }
    
    return { error, menus };

  }
  async _getMenus() {

    let key = LZString.compress(ENDPOINTS['MENUS']+'_'+JSON.stringify(this.headers));
    let ttl = 60 * 60 * 24 * 31;
    //let ttl = 600;

    let error, menus;

    let value = await LocalStorage.get(key);
    
    //console.log('getMenus CACHE:', value);

    if(typeof value != "undefined" && value !== null) {

      value = JSON.parse(LZString.decompress(value));
      
      error = value['error'];
      menus = value['menus'];

      return { error, menus };

    }
    
    let resp = await this._getMenus();
    error = resp['error'];
    menus = resp['menus'];

    //console.log('getMenus API:', resp);

    if(!error && menus) {
      await LocalStorage.put(key, LZString.compress(JSON.stringify(resp)), ttl);
      //console.log('getMenus SAVE CACHE:', success);
    }//if

    return { error, menus };

  }
  
  /**
   * Get the widgets available to the current user
   * 
   * @param {Array} categories 
   */
  async _getWidgets(categories) {

    if(!this.axiosInstance) this.createInstance();
    
    let key = LZString.compress(ENDPOINTS['WIDGETS']);

    this.createToken(key, key);

    var widgets, error = null;

    try {
      
      let response = await this.axiosInstance({
        cancelToken: ajaxRequest[key].token,
        method: 'GET',
        url: ENDPOINTS['WIDGETS'],
        headers: this.headers,
        validateStatus: false,
        params: {
          filter: {
            where: {
              categories: {
                inq: categories
              }
            }
          }
        }
      });

      /**
       * TODO: Filter by categories
       * TODO: Load typeRelation
       */
      
      //console.log(response);
        
      if(response.status === 200) {
        
        widgets = response.data;

        return { error, widgets }

      }//if
      else {
  
        // FAILED TO GET WIDGETS
        error = response;
  
      }//else

      ajaxRequest[key] = false;
      
    } catch(_error) {
      
      console.log(_error);
      error = _error;

    }
    
    return { error, widgets };

  }
  async getWidgets(categories) {

    let key = LZString.compress(ENDPOINTS['WIDGETS']+'_'+JSON.stringify(categories)+'_'+JSON.stringify(this.headers));
    let ttl = 60 * 60 * 24 * 31;
    //let ttl = 600;

    let error, widgets;

    let value = await LocalStorage.get(key);
    
    //console.log('getWidgets CACHE:', value);

    if(typeof value != "undefined" && value !== null) {

      value = JSON.parse(LZString.decompress(value));
      
      error = value['error'];
      widgets = value['widgets'];

      return { error, widgets };

    }
    
    let resp = await this._getWidgets(categories);
    error = resp['error'];
    widgets = resp['widgets'];

    //console.log('getWidgets API:', resp);

    if(!error && widgets) {
      await LocalStorage.put(key, LZString.compress(JSON.stringify(resp)), ttl);
      //console.log('getWidgets SAVE CACHE:', success);
    }//if

    return { error, widgets };

  }

  /**
   * Get the view available to the current user
   * 
   * @param {String} view 
   */
  async getView(_view) {

    if(!this.axiosInstance) this.createInstance();
    
    let key = LZString.compress(_view);

    this.createToken(key, key);

    var view, error = null;

    try {
      
      let response = await this.axiosInstance({
        cancelToken: ajaxRequest[key].token,
        method: 'GET',
        url: ENDPOINTS['VIEWS']+'/'+_view,
        headers: this.headers,
        validateStatus: false,
      });
      
      if(response.status === 200) {
        
        view = response.data;

        if(view.length) {
          view = view[0];
        }//if

        if(view) {

          let getResp = await this.getViewAcls(view.acls);
          let error_acls = getResp['error'];
          let acls = getResp['acls'];
          if(!error_acls) {
            view.acls = acls;
          }//if

        }//if

        return { error, view }

      }//if
      else {
  
        // FAILED TO GET VIEW
        error = response;
  
      }//else

      ajaxRequest[key] = false;
      
    } catch(_error) {
      
      console.log(_error);
      error = _error;

    }
    
    return { error, view };

  }
  async _getView(_view) {

    let key = LZString.compress(ENDPOINTS['VIEWS']+'/'+_view+'_'+JSON.stringify(this.headers));
    let ttl = 60 * 60 * 24 * 31;
    //let ttl = 600;

    let error, view;

    let value = await LocalStorage.get(key);
    
    //console.log('getView CACHE:', value);

    if(typeof value != "undefined" && value !== null) {

      value = JSON.parse(LZString.decompress(value));
      
      error = value['error'];
      view = value['view'];

      return { error, view };

    }
    
    let resp = await this._getView(_view);
    error = resp['error'];
    view = resp['view'];

    //console.log('getView API:', resp);

    if(!error && view) {
      await LocalStorage.put(key, LZString.compress(JSON.stringify(resp)), ttl);
      //console.log('getView SAVE CACHE:', success);
    }//if

    return { error, view };

  }

  /**
   * 
   * @param {String} key 
   * @param {String} path 
   */
  async _getModel(key, path, id) {

    if(!this.axiosInstance) this.createInstance();
    
    let _key = JSON.stringify(key)+JSON.stringify(path)+JSON.stringify(id);

    this.createToken(key, _key);

    var model, error = null;

    try {
      
      let response = await this.axiosInstance({
        cancelToken: ajaxRequest[_key].token,
        method: 'GET',
        url: path+'/'+id,
        headers: this.headers,
        validateStatus: false,
      });
      
      //console.log(response);
      
      if(response.status === 200) {
        
        model = response.data;

        return { error, model }

      }//if
      else {
  
        // FAILED TO GET model
        error = response;
  
      }//else

      ajaxRequest[_key] = false;
      
    } catch(_error) {
      
      console.log(_error);
      error = _error;

    }
    
    return { error, model }

  }
  async getModel(_key, _path, _id) {

    let key = LZString.compress(_path+'/'+_id+'_'+JSON.stringify(this.headers));
    let ttl = 60*15;
    //let ttl = 600;

    let error, model;

    let value = await LocalStorage.get(key);
    
    //console.log('getmodel CACHE:', value);

    if(typeof value != "undefined" && value !== null) {

      value = JSON.parse(LZString.decompress(value));
      
      error = value['error'];
      model = value['model'];

      return { error, model };

    }
    
    let resp = await this._getModel(_key, _path, _id);
    error = resp['error'];
    model = resp['model'];

    //console.log('getmodel API:', resp);

    if(!error && model) {
      await LocalStorage.put(key, LZString.compress(JSON.stringify(resp)), ttl);
      //console.log('getmodel SAVE CACHE:', success);
    }//if

    return { error, model };

  }
  
  /**
   * Get API Schemas
   */
  async _getSchemas() {

    //console.log('API getSchemas');

    if(!this.axiosInstance) this.createInstance();
    
    //let key = LZString.compress(ENDPOINTS['SCHEMAS']);

    var schemas, error = null;

    try {
      
      let response = await this.axiosInstance({
        method: 'GET',
        url: ENDPOINTS['SCHEMAS'],
        headers: this.headers,
        validateStatus: false,
      });
      
      //console.log(response);
      
      if(response.status === 200) {
        
        schemas = response.data;

        return { error, schemas }

      }//if
      else {
  
        // FAILED TO GET schemas
        error = response;
  
      }//else

      //ajaxRequest[key] = false;
      
    } catch(_error) {
      
      console.log(_error);
      error = _error;

    }
    
    return { error, schemas }

  }
  async getSchemas() {

    let key = LZString.compress(ENDPOINTS['SCHEMAS']+'_'+JSON.stringify(this.headers));
    let ttl = 60 * 60 * 24 * 31;

    let error, schemas;

    let value = await LocalStorage.get(key);
    
    //console.log('getschemas CACHE:', key, ttl, value);

    if(typeof value != "undefined" && value !== null) {

      value = JSON.parse(LZString.decompress(value));
      
      error = value['error'];
      schemas = value['schemas'];

      return { error, schemas };

    }
    
    let resp = await this._getSchemas();
    error = resp['error'];
    schemas = resp['schemas'];

    //console.log('getschemas API:', resp);

    if(!error && schemas) {
      await LocalStorage.put(key, LZString.compress(JSON.stringify(resp)), ttl);
      //console.log('getschemas SAVE CACHE:', key, ttl);
    }//if

    return { error, schemas };

  }

  /**
   * Create GET Request
   * 
   * @param {String} widgetId 
   * @param {String} url 
   * @param {Object} params 
   */
  createGet(widgetId, url, params) {

    if(!this.axiosInstance) this.createInstance();
    
    var req = null;

    let request = {
      method: 'GET',
      url: url,
      headers: this.headers,
      validateStatus: false,
      params: params,
    };

    //console.log(request);

    req = this.axiosInstance(request);
    
    return req;

  }

  /**
   * Create QUERY Request
   * 
   * @param {String} widgetId
   * @param {String} method
   * @param {String} url
   * @param {Object} params
   * @param {Object} data
   */
  createQuery(widgetId, method, url, params, data) {

    if(!this.axiosInstance) this.createInstance();
    
    var req = null;

    let request = {};
    if(method === 'GET') {
      
      request = {
        method: method,
        url: url,
        headers: this.headers,
        validateStatus: false,
        params: params,
      };

    }//if
    else {

      request = {
        method: method,
        url: url,
        headers: this.headers,
        validateStatus: false,
        data: data,
      };

    }//else

    //console.log(request);

    req = this.axiosInstance(request);
    
    return req;

  }

  /**
   * All API
   * 
   * @param {String} widgetId 
   * @param {Array} reqs 
   */
  async all(widgetId, reqs, reqsFilters) {

    //var resp;
    if(reqsFilters) {

      let key = LZString.compress(JSON.stringify(this.headers)+'_'+JSON.stringify(reqsFilters));
      let ttl = 60*15;

      let error, _resp;
      /*
      let value = await LocalStorage.get(key);
      
      //console.log('all CACHE:', key, ttl, value, typeof value != "undefined" && value !== null);

      if(typeof value != "undefined" && value !== null) {

        value = JSON.parse(LZString.decompress(value));
        
        return new Promise((resolve, reject) => {
          
          resolve(value)

        })

      }
      else {
        */

        return new Promise((resolve, reject) => {
          
          Promise.all(reqs)
          .then(function (results) {

            /*
            if(results) {
              LocalStorage.put(key, LZString.compress(JSON.stringify(results)), ttl);
              //console.log('all SAVE CACHE:', key, ttl);
            }//if
            */
            
            resolve(results)

          })
          
        })

      //}//else

    }//if
    else {

      return new Promise((resolve, reject) => {
        
        Promise.all(reqs)
        .then(function (results) {

          resolve(results)

        })
        
      })

    }//else
    
  }

  /**
   * Query API
   * 
   * @param {String} method
   * @param {String} url
   * @param {Object} params
   * @param {Object} data
   */
  async _query(widgetId, method, url, params, data) {

    if(!this.axiosInstance) this.createInstance();
    
    let key = LZString.compress(JSON.stringify(widgetId)+url);

    if(method === 'GET') {
      this.createToken(widgetId, key);
    }//if

    var resp, error = null;

    try {
      
      let request = {};
      if(method === 'GET') {
        
        request = {
          cancelToken: ajaxRequest[key].token,
          method: method,
          url: url,
          headers: this.headers,
          validateStatus: false,
          params: params,
        };

      }//if
      else {

        request = {
          method: method,
          url: url,
          headers: this.headers,
          validateStatus: false,
          data: data,
        };

      }//else

      //console.log(request);

      let response = await this.axiosInstance(request);
      
      //console.log(response.status);
      
      if(response.status === 200) {
        
        resp = response.data;

        return { error, resp }

      }//if
      else {
  
        // FAILED TO QUERY
        error = response;
  
      }//else

      if(method === 'GET') {
        ajaxRequest[key] = false;
      }//if
      
    } catch(_error) {
      
      //console.log(_error);
      error = _error;

    }
    
    return { error, resp };

  }
  async query(widgetId, method, url, params, data) {

    if(method === 'GET') {

      let key = LZString.compress(method+'_'+url+'_'+JSON.stringify(this.headers)+'_'+JSON.stringify(params)+'_'+JSON.stringify(data));
      let ttl = 60*15;

      let error, _resp;

      /*
      let value = await LocalStorage.get(key);
      
      //console.log('query CACHE:', key, ttl, value, typeof value != "undefined" && value !== null);

      if(typeof value != "undefined" && value !== null) {

        value = JSON.parse(LZString.decompress(value));
        
        return value;

      }
      */
      
      let resp = await this._query(widgetId, method, url, params, data);
      error = resp['error'];
      _resp = resp['resp'];

      //console.log('query API:', resp);

      /*
      if(!error && _resp) {
        await LocalStorage.put(key, LZString.compress(JSON.stringify(resp)), ttl);
        //console.log('query SAVE CACHE:', key, ttl);
      }//if
      */

      return { error: error, resp: _resp };

    }//if
    else {

      this.removeKeysOfEndpoint(url);

      return await this._query(widgetId, method, url, params, data);

    }//else

  }

  /**
   * Image API
   * 
   * @param {String} method
   * @param {String} url
   * @param {Object} params
   * @param {Object} data
   */
  async image(widgetId, method, url, params, data) {

    if(!this.axiosInstance) this.createInstance();
    
    let key = JSON.stringify(widgetId);

    if(method === 'GET') {
      this.createToken(widgetId, key);
    }//if

    var resp, error = null;

    try {
      
      let request = {};
      if(method === 'GET') {
        
        request = {
          cancelToken: ajaxRequest[key].token,
          method: method,
          url: url,
          responseType: 'arraybuffer',
          headers: this.headers,
          validateStatus: false,
          params: params,
        };

      }//if
      else {

        request = {
          method: method,
          url: url,
          responseType: 'arraybuffer',
          headers: this.headers,
          validateStatus: false,
          data: data,
        };

      }//else

      //console.log(request);

      let response = await this.axiosInstance(request);
      
      //console.log(response);
      
      if(response.status === 200) {
        
        resp = response.data;

        return { error, resp }

      }//if
      else {
  
        // FAILED TO QUERY
        error = response;
  
      }//else

      if(method === 'GET') {
        ajaxRequest[key] = false;
      }//if
      
    } catch(_error) {
      
      //console.log(_error);
      error = _error;

    }
    
    return { error, resp };

  }

  /**
   * Upload API
   *
   * @param {String} url
   * @param {Object} form
   *
   * @returns
   *
   * @memberOf Api
   */
  async upload(widgetId, url, form) {

    if(!this.axiosInstance) this.createInstance();
    
    let key = JSON.stringify(widgetId);

    this.createToken(widgetId, key);

    var resp, error = null;

    try {

      let request = {};
      let headers = {
        'content-type': 'multipart/form-data'
      };

      for(let key in this.headers) {
        headers[key] = this.headers[key];
      }//for

      request = {
        cancelToken: ajaxRequest[key].token,
        method: 'POST',
        url: url,
        headers: headers,
        validateStatus: false,
        data: form
      };

      //console.log(request);

      let response = await this.axiosInstance(request);

      //console.log(response);

      if (response.status === 200) {

        resp = response.data;

        return { error, resp }

      }//if
      else {

        // FAILED TO QUERY
        error = response;

      }//else

    } catch (_error) {

      console.log(_error);
      error = _error;

    }

    return { error, resp };

  }
  
}

var apiController = new ApiController();

export default apiController;
