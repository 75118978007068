import { freeSet } from '@coreui/icons'

import { cuSygnet } from './sygnet'
import { cuLogo } from './logo'
import { cuLogoNegative } from './logo-negative'

import {
  ltGroup, 
  ltText, 
  ltDropdown, 
  ltFileupload, 
  ltUserinput, 
  ltTemperature, 
  ltHumidity, 
  ltBurner, 
  ltMode, 
  ltWindowside, 
  ltWindowtop, 
  ltShadow, 
  ltGraph, 
  ltWindSpeed, 
  ltWindDir, 
  ltRainRate, 
  ltRaining,
  ltMobile,
  ltTablet,
  ltLaptop,
  ltDesktop,
  ltLargeDesktop,
  ltExtraLargeDesktop,
  ltSave,
  ltLayers,
  ltPencil,
  ltPlus,
  ltSeparator,
  ltOptions,
  ltClose,
  ltHome,
  ltCalendar,
  ltRecipe,
  ltMaintenace,
  ltQuality,
  ltMachine,
  ltMachine2,
  ltEBR,
  ltReporting,
  ltEnergy,
  ltProcessing,
  ltPending,
  ltSent,
  ltAcknowledge,
  ltDeployok,
  ltDeployNotok,
  ltCanceled,
  ltCancel,
  ltLeft,
  //cisChevronLeft,
} from './lumitio'

export const icons = Object.assign({}, {
  ...freeSet,
}, {
  ltGroup, 
  ltText, 
  ltDropdown, 
  ltFileupload, 
  ltUserinput, 
  ltTemperature, 
  ltHumidity, 
  ltBurner, 
  ltMode, 
  ltWindowside, 
  ltWindowtop, 
  ltShadow, 
  ltGraph, 
  ltWindSpeed, 
  ltWindDir, 
  ltRainRate, 
  ltRaining,
  ltMobile,
  ltTablet,
  ltLaptop,
  ltDesktop,
  ltLargeDesktop,
  ltExtraLargeDesktop,
  ltSave,
  ltLayers,
  ltPencil,
  ltPlus,
  ltSeparator,
  ltOptions,
  ltLeft,
  //cisChevronLeft,
  ltClose,
  ltHome,
  ltCalendar,
  ltRecipe,
  ltMaintenace, 
  ltQuality, 
  ltMachine,
  ltMachine2,
  ltEBR,
  ltReporting,
  ltEnergy,
  ltProcessing,
  ltPending,
  ltSent,
  ltAcknowledge,
  ltDeployok,
  ltDeployNotok,
  ltCanceled,
  ltCancel,
}, {
  cuSygnet,
  cuLogo,
  cuLogoNegative
})
