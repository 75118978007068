import React, { Component } from 'react';
import { connect } from 'react-redux'
import { HashRouter, Route, Switch } from 'react-router-dom';

import './scss/style.scss';

import apiController from './controllers/Api';

import { default as _i18n } from "i18next";
import HttpApi from 'i18next-http-backend';

const baseUrl = window.PRODTRACK_CONFIGS.API.HOST || "http://localhost:3000";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Logout = React.lazy(() => import('./views/pages/logout/Logout'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

class App extends Component {
  state = {
    loading: true,
    menus: [],
    links: [],
    schemas: [],
    accessToken: false,
    profile: false,
    i18n: {
      t: function(v) {
        return v;
      }
    }
  }

  componentDidMount = async () => {
    
    apiController.setStateCallback(this.setState);
    apiController.setProps(this.props);
    apiController.createInstance();

    await this.loadInternationalization();
    await this.loadData();

  }

  async loadInternationalization() {

    let profile = this.props.profile;

    // Load i18n lib
    _i18n
      .use(HttpApi)
      .init({
        initImmediate: false,
        lng: "pt",
        fallbackLng: "en",
        preload: ['pt', 'en'],
        ns: 'prodtrack',
        defaultNS: 'prodtrack',
        backend: {
          loadPath: baseUrl+'/locales/{{lng}}/{{ns}}',
          queryStringParams: {
            entity: profile ? profile.entity.id : undefined
          },
        },
        interpolation: {
          escapeValue: false
        }
      });

    _i18n.changeLanguage('pt');
    
    //i18n = _i18n;
    this.setState({
      i18n: _i18n
    });

  }

  /**
   * Pre-load menus and links
   */
  async loadData() {

    const dispatch = this.props.dispatch;

    let _accessToken = this.props.accessToken;
    let _profile = this.props.profile;

    if(_accessToken && _profile) {

      let cacheSet = {
        type: 'set',
      };

      var _links = false; //this.props.links;
      
      var _menus = false; //this.props.menus;
      
      var _schemas = false; //this.props.schemas;
      
      if(!_links) {
      
        var { links } = await apiController.getLinks();
        _links = links;

        cacheSet['links'] = _links;

      }//if
      
      if(!_menus) {

        var { menus } = await apiController.getMenus();
        _menus = menus;

        cacheSet['menus'] = _menus;

      }//if

      if(!_schemas) {
      
        // Get schemas
        let resp = await apiController.getSchemas();
        _schemas = resp['schemas'];
      
        cacheSet['schemas'] = _schemas;

      }//if

      await dispatch(cacheSet);

    }//if
    
    await this.setState({
      loading: false,
    });

  }

  render() {

    //let props = this.props;

    return (
      <HashRouter>
          <React.Suspense fallback={loading}>
            {
              this.state.loading ? <div className="pt-3 text-center">
                <div className="sk-spinner sk-spinner-pulse"></div>
              </div> : <Switch>
                <Route exact path="/login" name="Login Page" render={props => <Login {...props} i18n={this.state.i18n} API_HOST={baseUrl} />} />
                <Route exact path="/logout" name="Logout Page" render={props => <Logout {...props} i18n={this.state.i18n} API_HOST={baseUrl} />} />
                <Route exact path="/register" name="Register Page" render={props => <Register {...props} i18n={this.state.i18n} API_HOST={baseUrl} />} />
                <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} i18n={this.state.i18n} API_HOST={baseUrl} />} />
                <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} i18n={this.state.i18n} API_HOST={baseUrl} />} />
                <Route path="/" name="Home" render={props => <TheLayout {...props} i18n={this.state.i18n} API_HOST={baseUrl}/>} />
              </Switch>
            }
          </React.Suspense>
      </HashRouter>
    );
  }
}

//export default App;

const mapStateToProps = function(state) {
  return {
    menus: state.menus,
    links: state.links,
    schemas: state.schemas,
    accessToken: state.accessToken,
    profile: state.profile,
  }
}

export default connect(mapStateToProps)(App);